import React, { useContext, useState } from 'react';
import { makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ContextApp from '../../../../../context/ContextApp';
import FormTextField from '../../../../utilitiy/forms/FormTextField';
import FormSwitch from '../../../../utilitiy/forms/FormSwitch';
import FormSelect from '../../../../utilitiy/forms/FormSelect';
import ButtonContained from '../../../../utilitiy/buttons/ButtonContained';
import ButtonOutlined from '../../../../utilitiy/buttons/ButtonOutlined';
import { userCreate, userPatch } from '../../../../../Feathers/Services/UserService';
import { User } from '../../../../../server-interface';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.5),
  },
}));

interface SettingsUserManageInterface {
  selectedUser: Partial<User> | undefined;
  setSelectedUser: any;
}

const SettingsUserManage: React.FC<SettingsUserManageInterface> = ({
  selectedUser,
  setSelectedUser,
}) => {
  const classes = useStyles();
  const { authState } = useContext(ContextApp);
  const [error, setError] = useState<any>();
  const pageTitle = selectedUser ? 'Edit User' : 'Create New User';

  const schema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: !selectedUser ? Yup.string().required('Password is required') : Yup.string(),
    passwordAgain: !selectedUser ? Yup.string().required('Password is required') : Yup.string(),
    phoneNumber: Yup.number(),
  });

  const initialValues = {
    _id: selectedUser?._id ?? '',
    username: selectedUser?.username ?? '',
    password: '',
    passwordAgain: '',
    isCompanyAdmin: selectedUser?.isCompanyAdmin ?? false,
    phoneNumber: selectedUser?.phoneNumber ?? '',
    roles: selectedUser?.roles ? selectedUser?.roles.map((role: string) => ({ value: role, label: role })) : [],
    company: authState.company._id,
  }

  return (
    <div className={classes.root}>
      <Typography variant='body1'>{pageTitle}</Typography>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          const roles: Array<string> = values.roles.map((role: any) => role.value);
          if (!selectedUser) {
            const { _id, ...data } = values;
            // Create
            try {
              await userCreate({ ...data, roles });
              actions.resetForm();
            } catch (error) {
              setError(error);
            }
          } else {
            // Patch
            try {
              const { password, passwordAgain, ...patchValues } = values;
              await userPatch(values._id, { ...patchValues, roles });
              actions.resetForm();
              setSelectedUser(undefined);
            } catch (error) {
              setError(error);
            }
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          dirty,
          errors,
          submitForm,
          resetForm,
        }) => (
          <Form>
            <Field
              component={FormTextField}
              name='username'
              type='text'
              label='Username'
              isrequired
            />
            {
              !selectedUser ? (
                <>
                  <Field
                    component={FormTextField}
                    name='password'
                    type='password'
                    label='Password'
                    isrequired
                  />
                  <Field
                    component={FormTextField}
                    name='passwordAgain'
                    type='password'
                    label='Enter Password again'
                    isrequired
                  />
                </>
              ) : null
            }
            <Field
              component={FormSelect}
              name='roles'
              options={[
                { value: 'ecommerce', label: 'ecommerce' },
                { value: 'audit', label: 'audit' },
              ]}
              label='Roles'
              multiple
              isrequired
            />
            <Field
              component={FormTextField}
              name='phoneNumber'
              type='text'
              label='Phone Number'
            />
            <Field
              component={FormSwitch}
              name='isCompanyAdmin'
              type='checkbox'
              label='Company Admin'
            />
            <br />
            {
              error ? (
                <div style={{color: 'red'}}>
                  {error.message}
                </div>
              ) : null
            }
            <Grid container>
              <Grid item xs={6}>
                <ButtonOutlined
                  buttonVariant='normal'
                  style={{width: '98%'}}
                  onClick={() => {
                    setSelectedUser(undefined);
                    resetForm();
                  }}
                >
                  Cancel
                </ButtonOutlined>
              </Grid>
              <Grid item xs={6}>
                <ButtonContained
                  buttonVariant='normal'
                  type='submit'
                  style={{width: '98%'}}
                  onClick={() => submitForm()}
                  disabled={isSubmitting || !dirty || (Object.keys(errors).length > 0)}
                >
                  Submit
                </ButtonContained>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SettingsUserManage;
