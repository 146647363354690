import React from 'react';
import { makeStyles, Theme, useTheme, Typography } from '@material-ui/core';
import MaterialDialogTitle from '@material-ui/core/DialogTitle'
import { StatusType } from '../../../theme/ThemeStatus';

const useStyles = makeStyles((theme: Theme) => ({
  ...theme.commonStyle(),
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  topPaddingBar: {
    height: 15,
    width: '100%',
  },
  titleContainer: {
    flexGrow: 1,
    width: '100%',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'flex-start',
    paddingLeft: theme.spacing(2),
  },
  normal: {
    color: theme.customPalette.dialog.normal,
  },
  important: {
    color: theme.customPalette.dialog.important,
  },
  success: {
    color: theme.customPalette.dialog.success,
  },
  reminder: {
    color: theme.customPalette.dialog.reminder,
  },
}));

interface DialogTitleProps {
  variant: keyof typeof StatusType,
}

const DialogTitle: React.FC<DialogTitleProps> = ({
  variant,
  children,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <MaterialDialogTitle className={classes.root}>
      <div
        className={classes.topPaddingBar}
        style={{ backgroundColor: theme.customPalette.dialog[variant]}}
      >
      </div>
      <div className={classes.titleContainer}>
        <Typography variant='h5' className={`${classes.typographyBold} ${classes[variant]}`}>
          {children}
        </Typography>
      </div>
    </MaterialDialogTitle>
  );
};

export default DialogTitle;
