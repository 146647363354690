import { paramsForServer } from 'feathers-hooks-common'
import FeathersApp, { Service } from '../Feathers';
import { Workspace } from '../../server-interface';

export const workplaceFeathersService: any = FeathersApp.service(Service.Workspace);

export const workplaceFindByCompany = (companyId: string): Promise<Workspace[]> => (
  workplaceFeathersService.find(paramsForServer({
    paginate: false,
    query: {
      company: companyId,
      // $populate: ['workspaceAdmins', 'workspaceMembers'],
      $sort: {
        'updateInfo.dateTime': 1,
      },
    },
  }))
);

export const workspaceCreate = (data: Workspace): Promise<Workspace> => (
  workplaceFeathersService.create(data)
);

export const workspacePatch = (id: string, data: Partial<Workspace>): Promise<Workspace> => (
  workplaceFeathersService.patch(id, data)
);

