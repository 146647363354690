import React, { useContext } from 'react';
import { makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import ContextApp from '../../../context/ContextApp'
import ButtonBack from '../../utilitiy/buttons/ButtonBack';
import Settings from './Settings';
import SettingsProfile from './tabs/SettingsProfile/SettingsProfile';
import SettingsCompany from './tabs/SettingsCompany/SettingsCompany';
import SettingsWorkspace from './tabs/SettingsWorkspace/SettingsWorkspace';
import SettingsUser from './tabs/SettingsUser/SettingsUser';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: '0',
  },
  backButton: {
    paddingTop: theme.spacing(1),
  }
}))

const SettingsViewer: React.FC = () => {
  const classes = useStyles();
  const { authState } = useContext(ContextApp);
  const { isCompanyAdmin } = authState;
  // Generate list based on roles
  // isSuperAdmin
  // isCompanyAdmin
  // isWorkplaceAdmin

  const settingsList = [
    { title: 'Profile', component: <SettingsProfile /> },
    // { title: 'Workspace', component: <SettingsCompany /> }, // isWorkspaceAdmin
  ];

  if (isCompanyAdmin) {
    settingsList.push({ title: 'Company', component: <SettingsCompany /> });
    settingsList.push({ title: 'Workspaces', component: <SettingsWorkspace /> });
    settingsList.push({ title: 'Users', component: <SettingsUser /> });
  }

  return (
    <>
      <div className={classes.backButton}>
        <Grid
          container
          alignItems="center"
        >
          <Grid item>
            <ButtonBack />
          </Grid>
          <Grid item>
            <Typography variant='h5'>Settings</Typography>
          </Grid>
        </Grid>
      </div>
      <div className={classes.root}>
        <Settings pages={settingsList} />
      </div>
    </>
  );
};

export default SettingsViewer;
