import {
  MoreVert,
  ArrowForwardIos,
  ArrowBackIos,
  ArrowDropDown,
  Cancel,
  Add,
  Edit,
  AccountCircle,
  Notifications,
  Menu,
  Search,
  AccountTree,
  Dashboard,
  Storage,
  Store,
  People,
  Lock,
  Receipt,
  Remove,
  FormatListNumbered,
  DeleteForeverOutlined,
  FiberManualRecord,
  Visibility,
  Undo,
  FilterList,
  DirectionsCar,
  ExitToApp,
  Clear,
  SwapHoriz,
  Done,
} from '@material-ui/icons';

export const PageIcon = {
  Sample: AccountTree,
  Dashboard: Dashboard,
  Shelve: Storage,
  Inventory: Store,
  Client: People,
  Order: Receipt,
  PackingList: FormatListNumbered,
  IncomingList: FormatListNumbered,
  Vehicle: DirectionsCar,
  EasyStockOut: ExitToApp,
};

export const CommonIcon = {
  Add,
  Remove,
  More: MoreVert,
  Edit,
  Lock,
  AccountCircle,
  Notifications,
  Menu,
  Search,
  Cancel,
  Visibility,
  ArrowRight: ArrowForwardIos,
  ArrowLeft: ArrowBackIos,
  ArrowDown: ArrowDropDown,
  Delete: DeleteForeverOutlined,
  Dot: FiberManualRecord,
  Undo,
  FilterList,
  Clear,
  SwapHoriz,
  Done,
}
