import React, { useState } from 'react';
import { Form, Field } from 'formik';
import * as Yup from 'yup';
import FormTextField from '../../../../utilitiy/forms/FormTextField';
import DialogForm from '../../../../utilitiy/dialog/DialogForm';
import { userPatch } from '../../../../../Feathers/Services/UserService';

interface DialogInterface {
  isOpen: boolean;
  selectedUserId: string | undefined;
}

interface ChangePasswordDialogInterface {
  changePasswordDialog: DialogInterface;
  setChangePasswordDialog: any;
  requireOldPassword?: boolean,
}

const ChangePasswordDialog: React.FC<ChangePasswordDialogInterface> = ({
  requireOldPassword = true,
  changePasswordDialog,
  setChangePasswordDialog,
}) => {
  const [resultError, setResultError] = useState<any>();
  return (
    <DialogForm
      open={changePasswordDialog.isOpen}
      title='Change Password'
      variant='reminder'
      initialValues={{
        oldPassword: '',
        password: '',
        passwordAgain: '',
      }}
      validationSchema={{
        oldPassword: requireOldPassword ? Yup.string().required('Please enter old password') : Yup.string(),
        password: Yup.string().required('Password is required'),
        passwordAgain: Yup.string().required().oneOf([Yup.ref('password'), null], 'Passwords must match'),
      }}
      onSubmit={async (values, actions) => {
        const data = values.oldPassword ? {
          oldPassword: values.oldPassword,
          password: values.password,
        } : { password: values.password };
        try {
          await userPatch(changePasswordDialog.selectedUserId || '', data);
          setChangePasswordDialog({
            isOpen: false,
            selectedUserId: undefined,
          })
        } catch (error) {
          setResultError(error);
          if (resultError.message === 'Wrong Old Password') actions.setFieldError('oldPassword', 'Password is wrong');
        }
        actions.setSubmitting(false);
      }}
      onCancel={() => setChangePasswordDialog({ isOpen: false, selectedUserId: undefined })}
    >
      <Form>
        {
          requireOldPassword ? (
            <Field
              component={FormTextField}
              name='oldPassword'
              type='password'
              label='Old Password'
              isrequired
            />
          ) : null
        }
        <Field
          component={FormTextField}
          name='password'
          type='password'
          label='New Password'
          isrequired
        />
        <Field
          component={FormTextField}
          name='passwordAgain'
          type='password'
          label='Enter New Password Again'
          isrequired
        />
      </Form>
    </DialogForm>
  );
};

export default ChangePasswordDialog;
