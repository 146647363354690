import React, { ReactChild } from 'react';
import { makeStyles, useTheme, Theme, Button, ButtonProps } from '@material-ui/core';
import { StatusType } from '../../../theme/ThemeStatus';

interface StyleProps {
  color: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: StyleProps) => ({
    height: '30px',
    ...theme.commonStyle().button,
    backgroundColor: 'transparent',
    color: props.color,
    border: `1px solid ${props.color}`,
    textTransform: 'capitalize',
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(),
    '&:hover': {
      backgroundColor: theme.customPalette.extended.grey[10],
      color: theme.customPalette.extended.grey[100],
    },
    '&:active': {
      backgroundColor: theme.customPalette.extended.grey[70],
      color: theme.customPalette.extended.grey[10],
      border: `1px solid ${theme.customPalette.default.accent}`
    },
    '&:focus': {
      backgroundColor: theme.customPalette.extended.grey[70],
      color: theme.customPalette.extended.grey[10],
      border: `1px solid ${theme.customPalette.default.accent}`
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.customPalette.extended.grey[55]}`,
      color: theme.customPalette.extended.grey[55],
    },
  }),
}));

interface ButtonInterface extends ButtonProps {
  buttonVariant: keyof typeof StatusType;
  children: ReactChild;
}

const ButtonOutlined = ({
  children,
  buttonVariant,
  ...buttonProps
}: ButtonInterface) => {
  const theme = useTheme();
  const classes = useStyles({
    color: theme.customPalette.status[buttonVariant],
  });

  return (
    <Button
      classes={{ root: classes.root }}
      {...buttonProps}
    >
      { children }
    </Button>
  );
};

export default ButtonOutlined;
