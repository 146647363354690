import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, StylesProvider, CssBaseline } from '@material-ui/core';
import { getTheme } from '../../theme/Theme';
// import ContextApp from '../../context/ContextApp';
import PrivateRoute from './PrivateRoute';
import LoadingSpinner from '../LoadingSpinner';
import LoginPage from '../pages/authentication/LoginPage';
import HomePage from '../pages/home/HomePage';
import SamplePage from '../pages/sample';
import SettingsPage from '../pages/settings';
import Image from '../pages/image/Image';

const AppRoot = React.lazy(() => import('../Layout/AppRoot'));

const theme = getTheme(true);

const AppRouter = () => {
  // const { authState } = useContext(ContextApp);

  return (
    <StylesProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <CssBaseline />
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route
                  path='/login'
                  element={<LoginPage />}
                />
                <Route
                  path='/home'
                  element={<HomePage />}
                />
                <PrivateRoute
                  path='/*'
                  element={<AppRoot />}
                />
                <PrivateRoute
                  path='/settings'
                  element={<SettingsPage />}
                />
                <Route
                  path='/image/:id'
                  element={<Image />}
                />
                <Route
                  path='/sample/*'
                  element={<SamplePage />}
                />
              </Routes>
            </Suspense>
          </SnackbarProvider>
        </Router>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default AppRouter;
