import { Reducer } from 'react';
import { Company, Workspace } from '../server-interface';

export interface AuthenticationState {
  _id: string;
  username: string;
  company: Partial<Company>;
  isCompanyAdmin: boolean;
  isSuperAdmin: boolean;
  workspaces: Array<any>;
  roles: Array<string>;
  defaultWorkspace:  Partial<Workspace> | undefined | string;
  accessToken: string;
}

export const initialState = {
  _id: '',
  username: '',
  company: {},
  isCompanyAdmin: false,
  isSuperAdmin: false,
  workspaces: [],
  roles: [],
  defaultWorkspace: '',
  accessToken: '',
}

export const AuthenticationStateAction = {
  POPULATE_USER: 'populate-user' as const,
  UPDATE_USER: 'update-user' as const,
  UPDATE_USER_WS: 'update-user-ws' as const,
  LOGOUT: 'logout' as const,
  // TIMEOUT_LOGOUT: 'timeout-logout' as const,
  // FORCE_LOGOUT: 'force-logout' as const,
}

interface PopulateUserAction {
  type: typeof AuthenticationStateAction.POPULATE_USER;
  payload: {
    _id: string,
    username: string;
    company: Partial<Company>;
    isCompanyAdmin: boolean;
    isSuperAdmin: boolean;
    workspaces: Array<any>;
    roles: Array<string>;
    defaultWorkspace: Partial<Workspace> | undefined | string;
    accessToken: string;
  }
}

interface UpdateUserAction {
  type: typeof AuthenticationStateAction.UPDATE_USER;
  payload: {
    username: string;
    company: Partial<Company>;
    isSuperAdmin: boolean;
    isCompanyAdmin: boolean;
    workspaces: Array<any>;
    roles: Array<string>;
  }
}

interface UpdateUserWorkspaceAction {
  type: typeof AuthenticationStateAction.UPDATE_USER_WS;
  payload: {
    defaultWorkspace: Partial<Workspace> | undefined | string;
  }
}

interface LogoutAction {
  type: typeof AuthenticationStateAction.LOGOUT;
}

// interface TimeoutLogoutAction {
//   type: typeof AuthenticationStateAction.TIMEOUT_LOGOUT;
// }

// interface ForceLogoutAction {
//   type: typeof AuthenticationStateAction.FORCE_LOGOUT;
// }

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AuthenticationStateAction =
  | PopulateUserAction
  | UpdateUserAction
  | UpdateUserWorkspaceAction
  | LogoutAction
  // | TimeoutLogoutAction
  // | ForceLogoutAction;

// export const TIMEOUT_LOGOUT = 'timeout-logout'
// export const FORCED_LOGOUT = 'forced-logout'

const reducer: Reducer<AuthenticationState, AuthenticationStateAction> = (state, action) => {
  switch (action.type) {
    case AuthenticationStateAction.POPULATE_USER:
      return {
        ...state,
        _id: action.payload._id,
        username: action.payload.username,
        company: action.payload.company,
        isCompanyAdmin: action.payload.isCompanyAdmin,
        isSuperAdmin: action.payload.isSuperAdmin,
        workspaces: action.payload.workspaces,
        roles: action.payload.roles ? action.payload.roles : [],
        defaultWorkspace: action.payload.defaultWorkspace,
        accessToken: action.payload.accessToken,
      };
    case AuthenticationStateAction.UPDATE_USER:
      return {
        ...state,
        username: action.payload.username,
        company: action.payload.company,
        isSuperAdmin: action.payload.isSuperAdmin,
        isCompanyAdmin: action.payload.isCompanyAdmin,
        workspaces: action.payload.workspaces,
        roles: action.payload.roles,
      };
    case AuthenticationStateAction.UPDATE_USER_WS:
      return {
        ...state,
        defaultWorkspace: action.payload.defaultWorkspace,
      };
    case AuthenticationStateAction.LOGOUT:
      sessionStorage.clear();
      return initialState;
  }
}

export default reducer;
