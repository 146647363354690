import FeathersApp, { Service } from '../Feathers';
import { User } from '../../server-interface';

export const userFeathersService: any = FeathersApp.service(Service.User);

export const userFind = (): Promise<User[]> => (
  userFeathersService.find({
    query: {
      $sort: {
        'updateInfo.dateTime': 1,
      },
    },
  })
);

export const userGet = (userId: string): Promise<Partial<User>> => (
  userFeathersService.get({ _id: userId })
);

export const userFindByCompany = (companyId: string): Promise<User[]> => (
  userFeathersService.find({
    query: {
      company: companyId,
      $sort: {
        'updateInfo.dateTime': 1,
      },
    },
  })
);

export const userCreate = (data: Partial<User>): Promise<User> => (
  userFeathersService.create(data)
);

export const userPatch = (id: string, data: Partial<User>): Promise<User> => (
  userFeathersService.patch(id, data)
);
