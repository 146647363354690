import React, { useContext, useEffect } from 'react';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { isAuthenticated } from '../../helpers/authentication';
import ContextApp from '../../context/ContextApp';

const PrivateRoute = ({ element, ...others }: RouteProps) => {
  const contextApp = useContext(ContextApp);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated(contextApp)) {
      navigate('/login', { state: { from: pathname } });
    }
  }, [contextApp, navigate, pathname]);

  return (
    <Route
      {...others}
      element={element || (<div />)}
    />
  );
}

export default PrivateRoute;
