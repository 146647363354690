import { useEffect, useState } from 'react';
import { companyFeathersService, companyGet } from '../../../../../../Feathers/Services/CompanyService';
import { Company } from '../../../../../../server-interface';

const useFetchCompany = (companyId: string) => {
  const [company, setCompany] = useState<Company>();

  // Init
  useEffect(() => {
    const init = async () => {
      const fetchedCompany = await companyGet(companyId);
      setCompany(fetchedCompany);
    }
    init();
  }, [companyId]);

  // Listener
  useEffect(() => {
    const handlePatchCompany = (patchedCompany: Company) => {
      if (patchedCompany._id === companyId) setCompany(patchedCompany);
    }

    companyFeathersService.on('patched', handlePatchCompany);

    return () => {
      companyFeathersService.removeListener('patched', handlePatchCompany);
    }
  }, [companyId]);

  return company;
}

export default useFetchCompany;