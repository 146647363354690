import React, { useState } from 'react';
import { makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import SettingsUserList from './SettingsUserList';
import SettingsUserManage from './SettingsUserManage';
import ChangePasswordDialog from '../../components/ChangePassword/ChangePasswordDialog';
import { User } from '../../../../../server-interface';

const useStyles = makeStyles((theme: Theme) => ({
  root: {

  },
}));

const SettingsUser: React.FC = () => {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState<Partial<User> | undefined>();
  const [changePasswordDialog, setChangePasswordDialog] = useState({
    isOpen: false,
    selectedUserId: '',
  });

  return (
    <div className={classes.root}>
      <Typography variant='h5'>User Settings</Typography>
      <Grid container>
        <Grid item  xs={8}>
          <SettingsUserList
            setSelectedUser={setSelectedUser}
            setChangePasswordDialog={setChangePasswordDialog}
          />
          <ChangePasswordDialog
            changePasswordDialog={changePasswordDialog}
            setChangePasswordDialog={setChangePasswordDialog}
            requireOldPassword={false}
          />
        </Grid>
        <Grid item xs={4}>
          <SettingsUserManage
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SettingsUser;
