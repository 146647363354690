import { createContext, Dispatch } from 'react';
import { initialState, AuthenticationStateAction, AuthenticationState } from '../reducers/reducerAuthentication'

export interface ContextAppType {
  authState: AuthenticationState;
  authDispatch: Dispatch<AuthenticationStateAction>;
}

export default createContext<ContextAppType>({
  authState: initialState,
  authDispatch: () => { },
});
