import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { TextField, TextFieldProps } from 'formik-material-ui';

const useStyles = makeStyles((theme: Theme) => ({
  ...theme.commonStyle(),
}));

interface FormTextFieldInterface extends TextFieldProps {
  isrequired?: boolean;
}

const FormTextField = (props: FormTextFieldInterface) => {
  const classes = useStyles();
  const { isrequired, ...rest } = props;
  const label = isrequired ? `${props.label} *` : props.label;
  return (
    <TextField
      {...rest}
      label={label}
      className={classes.textfield}
    />
  );
};

export default FormTextField;
