import { Shelve, Inventory, Client, Carton } from '../server-interface';

export function escapeRegExp(searchTerm: string) {
  return searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const transformShelvesForSelect = (shelves: Shelve[]) => shelves.map((shelve: Shelve) => ({
  label: shelve.name,
  value: shelve._id,
}));

export const transformCartonsForSelect = (cartons: Carton[]) => cartons.map((carton: Carton) => ({
  label: carton.name,
  value: carton._id,
}));

export const transformInventoriesForSelect = (inventories: Inventory[]) => inventories.map((inventory: Inventory) => ({
  label: inventory.itemNumber,
  value: inventory._id,
}));

export const transformClientsForSelect = (clients: Client[]) => clients.map((client: Client) => ({
  label: client.name,
  value: client._id,
}));
