import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import io from 'socket.io-client';
import { ServiceTypes } from '../server-interface';
import appHooks from '../helpers/hooks/appHooks';

const socket = io(process.env.REACT_APP_APPSERVER_URL || 'http://localhost:3030', { transports: ['websocket'] });
const app = feathers<ServiceTypes>();

app.configure(socketio(socket, { timeout: 20000}));
app.configure(auth({
  // storage: window.localStorage
  storage: window.sessionStorage as any,
}));

app.hooks(appHooks);

export enum Service {
  AUTHENTICATION= 'authentication',
  Company = 'company',
  User = 'users',
  Workspace = 'workspace',
  WpSwitch = 'wpswitch',
  SHELVE = 'shelve',
  INVENTORY = 'inventory',
  INVENTORY_CATEGORY = 'inventoryCategory',
  CLIENT = 'client',
  Notification = 'notification',
  MOVEMENT = 'movement',
  MovementAdjustment = 'movement-adjustment',
  MovementPick = 'movement-pick',
  MovementIncoming = 'movement-incoming',
  Order = 'order',
  OrderItem = 'orderItem',
  PickList = 'pickList',
  PickListItem = 'pickListItem',
  // PickListCarton = 'pickListCarton',
  FILE = 'file',
  CHANNEL_ITEM = 'channelItem',
  IMAGE = 'image',
  CARTON = 'carton',
  CARTON_ITEM = 'cartonItem',
  VEHICLE = 'vehicle',
  PRODUCT = 'product',
  PRODUCT_CATEGORY = 'productCategory',
  MAGIC = 'magic',
  INCOMING_LIST = 'incomingList',
  INCOMING_LIST_ITEM = 'incomingListItem',
  STORAGE = 'storage',
};

// Increase timeout
(app.service('magic') as any).timeout = 200000;

export default app;
