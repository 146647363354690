import React, { useState } from 'react';
import { makeStyles, Theme, Grid, Typography } from '@material-ui/core';
import { Form, Field } from 'formik';
import * as Yup from 'yup';
import FormTextField from '../../../utilitiy/forms/FormTextField';
import FormSelectSingle from '../../../utilitiy/forms/FormSelectSingle';
import ButtonOutlined from '../../../utilitiy/buttons/ButtonOutlined';
import DialogAlert from '../../../utilitiy/dialog/DialogAlert';
import DialogForm from '../../../utilitiy/dialog/DialogForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  gridStyle: {
    padding: theme.spacing(1.5),
  },
}));

const SampleDialog: React.FC = () => {
  const classes = useStyles();
  const [normalDialog, setNormalDialog] = useState(false);
  const [importantDialog, setImportantDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [reminderDialog, setReminderDialog] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [formDialog, setFormDialog] = useState(false);

  return (
    <div className={classes.root}>
      <Typography variant='h5'>Sample Dialog</Typography>

      {/* Normal Dialog */}
      <Grid container direction='column' justify='center' alignItems='flex-start'>
        <Grid item className={classes.gridStyle}>
          <Typography variant='h6' >Alert Dialog</Typography>

          {/* Dialog Buttons */}
          <ButtonOutlined buttonVariant='normal' onClick={() => setNormalDialog(true)}>
            Normal Dialog
          </ButtonOutlined>
          &nbsp;
          <ButtonOutlined buttonVariant='normal' onClick={() => setImportantDialog(true)}>
            Important Dialog
          </ButtonOutlined>
          &nbsp;
          <ButtonOutlined buttonVariant='normal' onClick={() => setSuccessDialog(true)}>
            Success Dialog
          </ButtonOutlined>
          &nbsp;
          <ButtonOutlined buttonVariant='normal' onClick={() => setReminderDialog(true)}>
            Reminder Dialog
          </ButtonOutlined>

          {/* Alert Dialogs */}
          <DialogAlert
            title='Normal Dialog'
            open={normalDialog}
            onAcknowledge={() => setNormalDialog(false)}
          >
            <div style={{ width: '500px' }}>
              This is a normal dialog.
            </div>
          </DialogAlert>
          <DialogAlert
            title='Important Dialog'
            variant='important'
            open={importantDialog}
            onAcknowledge={() => setImportantDialog(false)}
          >
            <div style={{ width: '500px' }}>
              This is a important dialog.
            </div>
          </DialogAlert>
          <DialogAlert
            title='Success Dialog'
            variant='success'
            open={successDialog}
            onAcknowledge={() => setSuccessDialog(false)}
          >
            <div style={{ width: '500px' }}>
              This is a success dialog.
            </div>
          </DialogAlert>
          <DialogAlert
            title='Reminder Dialog'
            variant='reminder'
            open={reminderDialog}
            onAcknowledge={() => setReminderDialog(false)}
          >
            <div style={{ width: '500px' }}>
              This is a reminder dialog.
            </div>
          </DialogAlert>
        </Grid>
        <Grid item className={classes.gridStyle}>
          <Typography variant='h6' >Confirmation Dialog</Typography>
          <ButtonOutlined buttonVariant='normal' onClick={() => setConfirmationDialog(true)}>
            Confirmation Dialog
          </ButtonOutlined>
          <DialogAlert
            title='Confirmation Dialog'
            variant='normal'
            open={confirmationDialog}
            onAcknowledge={() => setConfirmationDialog(false)}
            onConfirm={() => setConfirmationDialog(false)}
          >
            <div style={{ width: '500px' }}>
              This is a Confirmation dialog.
            </div>
          </DialogAlert>
        </Grid>
        <Grid item className={classes.gridStyle}>
          <Typography variant='h6' >Dialog Form</Typography>
          <ButtonOutlined buttonVariant='normal' onClick={() => setFormDialog(true)}>
            Form Dialog
          </ButtonOutlined>
          <DialogForm
            open={formDialog}
            title='Form Dialog Sample'
            variant='normal'
            initialValues={{
              name: '',
              sex: '',
            }}
            validationSchema={{
              name: Yup.string().required('Name is required'),
              sex: Yup.string().required('Sex is required'),
            }}
            onSubmit={(values) => console.log('Submit Values: ', values)}
            onCancel={() => setFormDialog(false)}
          >
            <Form>
              <Field
                component={FormTextField}
                name='name'
                type='text'
                label='Name'
                isrequired
              />
              <Field
                component={FormSelectSingle}
                name='sex'
                type='select'
                options={[
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' }
                ]}
                label='Sex'
              />
            </Form>
          </DialogForm>
        </Grid>
      </Grid>
    </div>
  );
};

export default SampleDialog;
