import React from 'react';
import { makeStyles, Theme, Grid, Typography } from '@material-ui/core';
import { GridColDef, GridColumnHeaderParams, GridCellParams, GridValueGetterParams, GridValueFormatterParams } from '@material-ui/data-grid';
import DataTable from '../../../utilitiy/table/DataTable';
import XGridTable from '../../../utilitiy/table/XGridTable';
import { toDateTimeString } from '../../../../helpers/DateTimeHelper';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...theme.commonStyle().scrollbar,
    width: '100%',
    height: '100vh',
  },
  gridStyle: {
    padding: theme.spacing(1.5),
    width: '100%',
  },
}));

const SampleTable: React.FC = () => {
  const classes = useStyles();

  const sampleColumn: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.8,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{textAlign: 'center'}}>
          ID12
        </div>
      ),
      // width: 100,
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        return toDateTimeString(params.value);
      }
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
      // width: 200,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
      // width: 200,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        return `${params.value} Kg`;
      }
      // width: 200,
    },
    {
      field: 'age',
      headerName: 'Age',
      flex: 1,
      // width: 200,
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      description: 'This column has a value getting and is not sortable',
      sortable: false,
      flex: 2,
      // width: 300,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.getValue(params.id, 'firstName') || ''} ${params.getValue(params.id, 'lastName') || ''}`,
      renderCell: (params: GridCellParams) => (
        <strong style={{color: 'green'}}>
          {params.value}
        </strong>
      ),
    },
  ];

  const dateTimeNow = new Date();

  const sampleData = [
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 1)), id: 1, lastName: 'Snow', firstName: 'Jon', weight: 50, age: 35 },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 1)), id: 2, lastName: 'Lannister', firstName: 'Cersei', weight: 50, age: 42 },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 1)), id: 3, lastName: 'Lannister', firstName: 'Jaime', weight: 50, age: 45 },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 6)), id: 4, lastName: 'Stark', firstName: 'Arya', weight: 50, age: 16 },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 1)), id: 5, lastName: 'Targaryen', firstName: 'Daenerys', weight: 50, age: null },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 1)), id: 6, lastName: 'Melisandre', firstName: null, weight: 50, age: 150 },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 2)), id: 7, lastName: 'Clifford', firstName: 'Ferrara', weight: 50, age: 44 },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 1)), id: 8, lastName: 'Frances', firstName: 'Rossini', weight: 50, age: 36 },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 1)), id: 9, lastName: 'Roxie', firstName: 'Harvey', weight: 50, age: 65 },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 4)), id: 10, lastName: 'Roxie', firstName: 'Harvey', weight: 50, age: 65 },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 1)), id: 11, lastName: 'Roxie', firstName: 'Harvey', weight: 50, age: 65 },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 1)), id: 12, lastName: 'Roxie', firstName: 'Harvey', weight: 50, age: 65 },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 1)), id: 13, lastName: 'Roxie', firstName: 'Harvey', weight: 50, age: 65 },
    { createdOn: new Date(dateTimeNow.setDate(dateTimeNow.getDate() + 1)), id: 14, lastName: 'Roxie', firstName: 'Harvey', weight: 50, age: 65 },
  ];

  return (
    <div className={classes.root}>
      <Typography variant='h5'>Sample Table</Typography>
      <Grid container direction='column' justify='center' alignItems='flex-start'>
        <Grid item className={classes.gridStyle}>
          <Typography variant='h6' >Basic Table</Typography>
          <div style={{ height: '500px', width: '100%' }}>
            <DataTable
              autoHeight={false}
              columns={sampleColumn}
              data={sampleData}
            />
          </div>
        </Grid>
        <Grid item className={classes.gridStyle}>
          <Typography variant='h6' >X Grid Table</Typography>
          <div style={{ height: 520, width: '100%' }}>
            <XGridTable
              columns={sampleColumn}
              data={sampleData}
              autoHeight={false}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SampleTable;
