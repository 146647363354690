import React, { useState } from 'react';
import { makeStyles, Theme, Typography, IconButton, Tooltip, Menu, MenuItem } from '@material-ui/core';
import { CommonIcon } from '../../helpers/IconManager';
// import { IsMobileView } from '../../helpers/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
  },
  options: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuItem: {
    minWidth: '180px',
  }
}))

interface OptionsInterface {
  optionTitle: string;
  optionAction: () => void;
}

interface PageTitleInterface {
  title: string | JSX.Element;
  titleColor?: string; 
  moreOptions?: OptionsInterface[];
  rightIcon?: any;
}

const PageTitle: React.FC<PageTitleInterface> = ({
  title,
  titleColor,
  moreOptions,
  rightIcon,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>();
  const generateOptionButtons = () => moreOptions?.map((item: OptionsInterface) => (
    <MenuItem
      key={item.optionTitle}
      className={classes.menuItem}
      onClick={() => {
        setAnchorEl(null);
        item.optionAction();
      }}
    >
      {item.optionTitle}
    </MenuItem>
  ));

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {
          typeof title === 'string' ? (
            <Typography
              variant="h5"
              style={{ color: titleColor }}
            >
              {title}
            </Typography>
          ) : title
        }
      </div>
      <div className={classes.options} style={{ display: !!moreOptions ? '' : 'none' }}>
        <IconButton size='small' onClick={(event) => setAnchorEl(event.currentTarget)}>
          <Tooltip title='Actions'>
            <CommonIcon.More />
          </Tooltip>
        </IconButton>
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          { generateOptionButtons() }
        </Menu>
      </div>
      <div className={classes.options} style={{ display: !!rightIcon ? '' : 'none' }}>
        { rightIcon }
      </div>
    </div>
  );
};

export default PageTitle;
