import React, { useEffect, useState, useContext } from 'react';
import { Form, Field } from 'formik';
import * as Yup from 'yup';
import ContextApp from '../../../../../context/ContextApp';
import DialogForm from '../../../../utilitiy/dialog/DialogForm'
import FormTextField from '../../../../utilitiy/forms/FormTextField';
import FormSelect from '../../../../utilitiy/forms/FormSelect';
import { workspaceCreate, workspacePatch } from '../../../../../Feathers/Services/WorkplaceService';
import { userFindByCompany } from '../../../../../Feathers/Services/UserService';
import { User } from '../../../../../server-interface';

interface SettingsWorkplaceFormInterface {
  workspaceFormInfo: any;
  setWorkspaceFormInfo: any;
}

const SettingsWorkplaceForm: React.FC<SettingsWorkplaceFormInterface> = ({
  workspaceFormInfo,
  setWorkspaceFormInfo,
}) => {
  const { authState } = useContext(ContextApp);
  const [companyUsers, setCompanyUsers] = useState<any>();

  useEffect(() => {
    const transformCompanyUsers = (users: User[]) => users.map((user: User) => ({
      value: user._id,
      label: user.username,
    }));
    const init = async () => {
      const fetchedUsers = await userFindByCompany(authState.company._id || '');
      setCompanyUsers(transformCompanyUsers(fetchedUsers));
    }
    init();
  }, [authState.company._id]);

  const generateFieldOptions = (users: any) => users.map((user: any) => ({
    value: user._id,
    label: user.username,
  }));

  const initialValues = {
    name: workspaceFormInfo.selectedWorkspace?.name ?? '',
    address: workspaceFormInfo.selectedWorkspace?.address ?? '',
    phoneNumber: workspaceFormInfo.selectedWorkspace?.phoneNumber ?? '',
    workspaceAdmins: workspaceFormInfo.selectedWorkspace?.workspaceAdmins ? generateFieldOptions(workspaceFormInfo.selectedWorkspace.workspaceAdmins) : [],
    workspaceMembers: workspaceFormInfo.selectedWorkspace?.workspaceMembers ? generateFieldOptions(workspaceFormInfo.selectedWorkspace.workspaceMembers) : [],
    company: authState.company._id,
  }

  const validationSchema = {
    name: Yup.string().required('Name is required'),
    phoneNumber: Yup.number(),
  }

  const closeDialog = () => {
    setWorkspaceFormInfo({
      isOpen: false,
      selectedWorkspace: undefined,
    });
  };

  return (
    <DialogForm
      open={workspaceFormInfo.isOpen}
      title='Workspace'
      variant='normal'
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        const getValues = (fieldValues: any) => fieldValues.map((fieldValue: any) => fieldValue.value);
        const data = {
          ...values,
          workspaceAdmins: getValues(values.workspaceAdmins),
          workspaceMembers: getValues(values.workspaceMembers)
        };
        if (!workspaceFormInfo.selectedWorkspace) {
          await workspaceCreate(data);
        } else {
          await workspacePatch(workspaceFormInfo.selectedWorkspace._id, data);
        }
        actions.setSubmitting(false);
        closeDialog();
      }}
      onCancel={closeDialog}
    >
      <div style={{ width: '500px' }}>
        <Form>
          <Field
            component={FormTextField}
            name='name'
            type='text'
            label='Name'
            isrequired
          />
          <Field
            component={FormTextField}
            name='address'
            type='text'
            label='Address'
          />
          <Field
            component={FormTextField}
            name='phoneNumber'
            type='text'
            label='Phone number'
          />
          <Field
            component={FormSelect}
            name='workspaceAdmins'
            options={companyUsers}
            label='Admins'
            multiple
          />
          <Field
            component={FormSelect}
            name='workspaceMembers'
            options={companyUsers}
            label='Members'
            multiple
          />
        </Form>
      </div>
    </DialogForm>
  );
};

export default SettingsWorkplaceForm;
