import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, IconButton, TextField, } from '@material-ui/core';
import { XGrid, GridColDef,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@material-ui/x-grid';
import { escapeRegExp } from '../../../helpers/formHelpers';
import { Search as SearchIcon, Clear as ClearIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  tableRoot: {
    color: theme.customPalette.extended.grey[5],
    borderColor: theme.customPalette.extended.grey[50],
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  icon: {
    color: theme.customPalette.default.light,
    "&:hover": {
      color: theme.customPalette.default.accent,
    },
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& label.Mui-focused': {
      color: theme.customPalette.default.accent,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.customPalette.default.accent,
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  panelColor: {
    background: 'red',
    color: 'white',
  },
}));

interface XGridTableInterface {
  columns: GridColDef[];
  data: any[];
  isLoading?: boolean;
  autoHeight?: boolean;
  disableSelectionOnClick?: boolean;
  disableColumnMenu?: boolean;
  allowExport?: boolean;
}

const XGridTable: React.FC<XGridTableInterface> = ({
  columns,
  data,
  isLoading = false,
  autoHeight = true,
  disableSelectionOnClick = true,
  disableColumnMenu = true,
  allowExport = false,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState<any[]>(data);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const requestSearch = (searchValue: string) => {
    setSearchQuery(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <XGrid
      className={classes.tableRoot}
      columns={columns}
      rows={rows}
      loading={isLoading}
      components={{ Toolbar: CustomToolBar }}
      componentsProps={{
        toolbar: {
          value: searchQuery,
          onChange: (event: any) => requestSearch(event.target.value),
          clearSearch: () => requestSearch(''),
          allowExport,
        },
      }}
      autoHeight={autoHeight}
      disableSelectionOnClick={disableSelectionOnClick}
      disableColumnMenu={disableColumnMenu}
    />
  );
};

export default XGridTable;


interface CustomToolBarInterface {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
  allowExport: boolean;
}

const CustomToolBar: React.FC<CustomToolBarInterface> = ({
  clearSearch,
  onChange,
  value,
  allowExport,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        {
          allowExport ? (
            <GridToolbarExport className={classes.icon} />
          ) : null
        }
        <GridToolbarColumnsButton className={classes.icon} />
        <GridToolbarFilterButton className={classes.icon} />
        <GridToolbarDensitySelector className={classes.icon} />
      </div>
      <TextField
        variant="standard"
        value={value}
        onChange={onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize='medium' />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};
