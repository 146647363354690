import React, { useContext } from 'react';
import {
  makeStyles, Theme, Typography,
  Table, TableHead, TableBody, TableRow, TableCell,
  IconButton, Tooltip,
} from '@material-ui/core';
import ContextApp from '../../../../../context/ContextApp';
import { CommonIcon } from '../../../../../helpers/IconManager';
import useFetchCompanyUsers from './hooks/useFetchCompanyUser';
import { User } from '../../../../../server-interface';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowY: 'scroll',
  },
  table: {

  },
  tableHead: {
    fontWeight: 'bold',
  },
}));

interface SettingsUserListInterface {
  setChangePasswordDialog: any;
  setSelectedUser: any;
}

const SettingsUserList: React.FC<SettingsUserListInterface> = ({
  setChangePasswordDialog,
  setSelectedUser,
}) => {
  const classes = useStyles();
  const { authState } = useContext(ContextApp);
  const users = useFetchCompanyUsers(authState.company._id || '');

  const displayUsers = () => users.map((user: User) => {
    const workspaces = user.workspaces ? user.workspaces.map((item: any) => (
      <Typography key={`${user.username}-${item.name}`}>{item.name}</Typography>
    )) : 'No Workspace';
  
    return (
      <TableRow key={user.username}>
        <TableCell>{user.username}</TableCell>
        <TableCell>{user.isCompanyAdmin ? 'Yes' : 'No'}</TableCell>
        <TableCell>{workspaces}</TableCell>
        <TableCell>{user.roles?.join(', ') || ''}</TableCell>
        <TableCell align='center'>
          <IconButton
            onClick={() => {
              setSelectedUser(user);
            }}
          >
            <Tooltip title='Edit'>
              <CommonIcon.Edit />
            </Tooltip>
          </IconButton>
          <IconButton
            onClick={() => {
              setChangePasswordDialog({
                isOpen: true,
                selectedUserId: user._id,
              });
            }}
          >
            <Tooltip title='Change Password'>
              <CommonIcon.Lock />
            </Tooltip>
          </IconButton>
        </TableCell>
      </TableRow>
    )
  });

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} align='left'>Username</TableCell>
            <TableCell className={classes.tableHead} align='left'>Company Admin</TableCell>
            <TableCell className={classes.tableHead} align='left'>Default Workspace</TableCell>
            <TableCell className={classes.tableHead} align='left'>Roles</TableCell>
            <TableCell className={classes.tableHead} align='center'>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayUsers()}
        </TableBody>
      </Table>
    </div>
  );
};

export default SettingsUserList;
