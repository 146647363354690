import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles, Typography, Paper } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import ContextApp from '../../../context/ContextApp';
import { authenticate, isAuthenticated } from '../../../helpers/authentication';
// import FormText from '../../utilitiy/forms/FormText';
import FormTextField from '../../utilitiy/forms/FormTextField';
import ButtonContained from '../../utilitiy/buttons/ButtonContained';
import LoadingSpiiner from '../../LoadingSpinner';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  BoxStyle: {
    margin: 'auto',
    width: '35%',
    marginTop: "10%",
    padding: '1rem',
    minWidth: "350px",
    maxWidth: "450px",
  },
  buttonStyle: {
    marginTop: '1rem',
    width: '100%'
  }
}));

const LoginPage: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const contextApp = useContext(ContextApp);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isUnmounted = false;
    const authenticated = isAuthenticated(contextApp);
    // Set workspace from localstorage
    if (authenticated) {
      const from = (location.state as any || {}).from;
      const redirectTo = !from || from === '/' ? '/dashboard' : from;
      navigate(redirectTo);
    }

    // window.localStorage.setItem('activews', contextApp.)
    const auth = async () => {
      const err = await authenticate(contextApp);
      if (!isUnmounted) setIsLoading(err === null);
    }

    if (!authenticated) auth();

    return () => { isUnmounted = true; }
  }, [contextApp, location.state, navigate]);

  const schema = Yup.object().shape({
    username: Yup.string().required('Username is required.'),
    password: Yup.string().required('Password is required.'),
  });

  const initialValues = {
    username: '',
    password: '',
  }

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    const error = await authenticate(contextApp, {
      username: values.username,
      password: values.password,
    });
    if (error) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  }

  return (
    <div className={ classes.root }>
      <Paper className={ classes.BoxStyle }>
        <Typography variant="h5">
          Login
        </Typography>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleBlur,
            isSubmitting,
            dirty,
            submitForm,
          }) => (
            <Form>
              <Field
                component={FormTextField}
                name='username'
                type='text'
                label='Username'
                isrequired
              />
              <Field
                component={FormTextField}
                name='password'
                type='password'
                label='Password'
                isrequired
              />
              {
                isLoading ? (
                  <LoadingSpiiner />
                ) : (
                  <ButtonContained
                    type='submit'
                    onClick={() => submitForm()}
                    buttonVariant='normal'
                    style={{ width: '100%' }}
                  >
                    Submit
                  </ButtonContained>
                )
              }
            </Form>
          )}
        </Formik>
        {
          errorMessage ? (
            <div style={{ width: '100%', color: 'red', textAlign: 'center' }}>
              {errorMessage}
            </div>
          ) : (null)
        }
      </Paper>
    </div>
  );
};

export default LoginPage;
