import React from 'react';
import { makeStyles, Theme, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Select, SelectProps } from 'formik-material-ui';

const useStyles = makeStyles((theme: Theme) => ({
  ...theme.commonStyle(),
  root: {
    width: '100%',
  },
}));

interface MenuOptionInterface {
  value: string;
  label: string;
}

interface FormSelectSingleInterface extends SelectProps {
  options: MenuOptionInterface[];
  isRequired?: boolean;
}

const FormSelectSingle = (props: FormSelectSingleInterface) => {
  const classes = useStyles();
  const { options, isRequired, ...rest } = props;
  const label = props.isRequired ? `${props.label} *` : props.label;
  const generateOptions = options.map((item: MenuOptionInterface) => (
    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
  ));

  return (
    <>
    <FormControl className={classes.root}>
      <InputLabel style={{color: 'white'}}>{label}</InputLabel>
      <Select {...rest} className={classes.textfield}>
        {generateOptions}
      </Select>
    </FormControl>
    </>
  );
};

export default FormSelectSingle;
