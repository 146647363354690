import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, CircularProgress } from '@material-ui/core';
import FeathersApp, { Service } from '../../../Feathers/Feathers';
import { useParams } from 'react-router-dom';
import { Img } from 'react-image';
// import watermark from 'watermarkjs';
// import watermark from 'purejswatermark/dist/watermark';

const useStyles = makeStyles((theme: Theme) => ({
  galleryThumb: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 90,
    height: 90,
    border: '2px solid transparent',
    boxShadow: `2px 2px 5px ${theme.customPalette.default.dark}`,
    '&:hover': {
      transition: 'all .2s',
      border: `2px solid ${theme.customPalette.default.dark}`,
    },
    marginBottom: theme.spacing(0.2),
  },
  circularProgress: {
    color: theme.customPalette.extended.accent[50],
  },
}));

const Image: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [image, setImage] = useState({
    uri: '',
    name: undefined,
  });

  useEffect(() => {
    const init = async () => {
      const imageService: any = FeathersApp.service(Service.IMAGE);
      const image = await imageService.get(id);
      setImage({name: image.name, uri: image.uri});
    }
    init();
  }, [id]);

  const LoadingSpinner = <CircularProgress className={classes.circularProgress} size={150} />

  return (
    <div>
      {
        image.uri ? (
          <Img
            src={image.uri}
            loader={LoadingSpinner}
            alt={image.name}
          />
        ) : null
      }
    </div>
  );
};

export default Image;
