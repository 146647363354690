import React from 'react';
import { makeStyles, Theme, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...theme.commonStyle().scrollbar,
    width: '100%',
    height: '100vh',
  },
  gridStyle: {
    padding: theme.spacing(1.5),
  },
}));

const SampleTypography: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant='h5'>Sample Typography</Typography>
      <Grid container direction='column' justify='center' alignItems='flex-start'>
        <Grid item className={classes.gridStyle}>
          variant='h1'
          <Typography variant='h1'>Sample Text</Typography>
        </Grid>
        <Grid item className={classes.gridStyle}>
          variant='h2'
          <Typography variant='h2'>Sample Text</Typography>
        </Grid>
        <Grid item className={classes.gridStyle}>
          variant='h3'
          <Typography variant='h3'>Sample Text</Typography>
        </Grid>
        <Grid item className={classes.gridStyle}>
          variant='h4'
          <Typography variant='h4'>Sample Text</Typography>
        </Grid>
        <Grid item className={classes.gridStyle}>
          variant='h5'
          <Typography variant='h5'>Sample Text</Typography>
        </Grid>
        <Grid item className={classes.gridStyle}>
          variant='h6'
          <Typography variant='h6'>Sample Text</Typography>
        </Grid>
        <Grid item className={classes.gridStyle}>
          variant='body1'
          <Typography variant='body1'>Sample Text</Typography>
        </Grid>
        <Grid item className={classes.gridStyle}>
          variant='body2'
          <Typography variant='body2'>Sample Text</Typography>
        </Grid>
        <Grid item className={classes.gridStyle}>
          variant='subtitle1'
          <Typography variant='subtitle1'>Sample Text</Typography>
        </Grid>
        <Grid item className={classes.gridStyle}>
          variant='subtitle2'
          <Typography variant='subtitle2'>Sample Text</Typography>
        </Grid>
        <Grid item className={classes.gridStyle}>
          variant='caption'
          <br />
          <Typography variant='caption'>Sample Text</Typography>
        </Grid>
        <Grid item className={classes.gridStyle}>
          variant="overline" display="block"
          <br />
          <Typography variant="overline" display="block">Category</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default SampleTypography;
