import React from 'react';
import { makeStyles, Theme, TextField, Chip } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams, AutocompleteProps } from 'formik-material-ui-lab';

const useStyles = makeStyles((theme: Theme) => ({
  ...theme.commonStyle(),
  root: {
    width: '100%',
  },
}));

interface FormTagsFreeSoloInterface extends AutocompleteProps<string, true, true, true> {
  // onChange?: any;
  isrequired?: boolean;
  label: string,
}

const FormTagsFreeSolo = (props: FormTagsFreeSoloInterface) => {
  const classes = useStyles();
  const { isrequired, ...rest } = props;
  const label = isrequired ? `${props.label} *` : props.label;

  return (
    <div className={classes.root}>
      <Autocomplete
        {...rest}
        freeSolo
        autoSelect
        multiple
        // getOptionLabel={(options: any) => options.label ? options.label : ''}
        // getOptionSelected={(option: any, value: any) => {
        //   if (value === '') return true;
        //   return option.value === value.value
        // }}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip style={{height: '36px'}} variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            className={classes.textfield}
            {...params}
            error={props.form.touched[rest.field.name] && !!props.form.errors[rest.field.name]}
            helperText={props.form.touched[rest.field.name] && props.form.errors[rest.field.name]}
            label={label}
            variant="standard"
          />
        )}
        options={[]}
      />
    </div>
  );
}

export default FormTagsFreeSolo;
