import { makeStyles, Theme } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { TextField, TextFieldProps } from 'formik-material-ui';

const useStyles = makeStyles((theme: Theme) => ({
  ...theme.commonStyle(),
  zAxisSet: {
    zIndex: 1302,
    padding: 0,
    margin: 0,
  },
}));

interface FormDateFieldInterface extends TextFieldProps {
  isrequired?: boolean;
}

const FormDateField = (props: FormDateFieldInterface) => {
  const classes = useStyles();
  const { isrequired, ...rest } = props;
  const label = isrequired ? `${props.label} *` : props.label;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={classes.zAxisSet}
        margin="normal"
        label={label}
        format="dd/MM/yyyy"
        value={props.field.value}
        onChange={(event) => {
          props.form.setFieldValue(props.field.name, event);
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        fullWidth
      />
      {/* <KeyboardDatePicker
        className={classes.zAxisSet}
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        label={label}
        value={props.field.value}
        onChange={(event) => {
          props.form.setFieldValue('eventDate', event);
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      /> */}
    </MuiPickersUtilsProvider>
  );
};

export default FormDateField;
