import React from 'react';
import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const LoadingSpinner: React.FC = () => {
  const classes = useStyles();

  return (
    <Backdrop className={ classes.backdrop } open>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default LoadingSpinner;
