import { makeStyles, Theme } from '@material-ui/core';

export const useStatusStyles = makeStyles((theme: Theme) => ({
  normal: {
    border: `1px solid ${theme.customPalette.status.normal}`,
  },
  important: {
    border: `1px solid ${theme.customPalette.status.important}`,
  },
  success: {
    border: `1px solid ${theme.customPalette.status.success}`,
  },
  reminder: {
    border: `1px solid ${theme.customPalette.status.reminder}`,
  },
  dialogPaper: {
    backgroundColor: theme.customPalette.default.secondary,
    minWidth: theme.customPalette.dialog.minWidth,
  },
  dialogError: {
    color: theme.customPalette.status.important,
    marginBottom: theme.spacing(1),
  },
}))

export const StatusType = {
  normal: 'normal',
  important: 'important',
  success: 'success',
  reminder: 'reminder',
}
