import React, { useEffect, useReducer, useRef } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import ContextApp from './context/ContextApp';
import ReducerAuthentication, { initialState } from './reducers/reducerAuthentication';
import { refreshToken, logout } from './helpers/authentication';
import AppRouter from './components/navigation/AppRouter';
import './App.css';

const App: React.FC = () => {
  const [authState, authDispatch] = useReducer(ReducerAuthentication, initialState);

  const contextApp = {
    authState,
    authDispatch,
  }

  const sessionTimeout = useRef<NodeJS.Timeout | undefined>();

  const clearSession = () => {
    if (sessionTimeout.current) {
      clearTimeout(sessionTimeout.current);
      sessionTimeout.current = undefined;
    }
  }

  useEffect(() => {
    clearSession();
    if (authState.accessToken !== '') {
      const { iat = 0, exp = 0, iss } = jwtDecode<JwtPayload>(authState.accessToken);
      if (iss !== 'feathers') return;
      const MILLISECONDS = 1000;
      const THRESHOLD = 200;
      sessionTimeout.current = setTimeout(() => {
        logout(authDispatch);
        window.location.replace(process.env.REACT_APP_APPCLIENT_URL || "https://japlauto.com/login");
      }, (exp - iat) * MILLISECONDS - THRESHOLD); // logout 0.2 seconds before session expiry
    }
  }, [authState.accessToken, authDispatch]);

  useEffect(() => {
    const listener = () => refreshToken(contextApp.authDispatch);
    
    const events = ['load', 'click', 'keypress'];
    events.forEach((wEvent) => window.addEventListener(wEvent, listener));
    return () => {
      events.forEach((wEvent) => window.removeEventListener(wEvent, listener));
    }
  }, [contextApp.authDispatch]);

  return (
    <ContextApp.Provider value={contextApp}>
        <AppRouter />
    </ContextApp.Provider>
  );
}

export default App;
