import { Theme } from '@material-ui/core';

export interface CommonStyleOptions {
  routerLink: any;
  scrollbar: any;
  appbarSpacer: any;
  transparentDividerSpacer: any;
  typographyTopBarStatus: any;
  typographyBold: any;
  typographySemiBold: any;
  typographyClickable: any;
  textfield: any;
  button: any;
  labelDetailIcon: any;
  virtuosoRoot: any;
}

export default function getAppCommonStyle(theme: Theme) {
  return {
    routerLink: {
      textDecoration: 'none',
      '&:link': {
        color: '#FFF',
      },
      '&:visited': {
        color: '#FFF',
      },
      '&:active': {
        color: '#FFF',
      },
    },
    scrollbar: {
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar: horizontal': {
        display: 'none',
      },
      '&::-webkit-scrollbar-track': {
        display: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.customPalette.default.highlightNegative,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    appbarSpacer: {
      minHeight: theme.customVariable.appBar.height,
    },
    transparentDividerSpacer: {
      width: '100%',
      height: theme.spacing(),
      backgroundColor: 'transparent',
    },
    typographyTopBarStatus: {
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    typographyBold: {
      fontWeight: 'bold',
    },
    typographySemiBold: {
      fontWeight: 600,
    },
    typographyClickable: {
      textDecoration: 'underline',
      cursor: 'underline',
      color: theme.customPalette.default.accent,
    },
    textfield: {
      '& label.Mui-focused': {
        color: theme.customPalette.default.accent,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.customPalette.default.accent,
      },
      width: '100%',
      height: '100%',
      marginBottom: theme.spacing(1),
      // backgroundColor: theme.customPalette.form.background,
      // margin: 0,
      // padding: 0,
      // width: '100%',
      // height: '100%',
      // '&:focus': {
      //   backgroundColor: theme.customPalette.form.backgroundFocus,
      //   // backgroundColor: theme.customPalette.form.backgroundFocus,
      //   color: theme.customPalette.form.textrFocus,
      // },
      // '&:active': {
      //   backgroundColor: theme.customPalette.form.backgroundActive,
      //   color: theme.customPalette.form.textActive,
      // },
    },
    button: {
      borderRadius: theme.customVariable.common.borderRadius,
      paddingTop: theme.spacing(),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(2),
    },
    labelDetailIcon: {
      fill: theme.customPalette.extended.grey[40],
      color: theme.customPalette.extended.grey[40],
      width: 20,
      height: 20,
    },
    virtuosoRoot: {
      width: '100%',
      height: 'calc(100% - 64px - 35px - 44px)',
      padding: theme.spacing(1),
    },
  };
}
