import React from 'react';
import { makeStyles, Theme, Grid, Typography } from '@material-ui/core';
import ButtonBorderless from '../../../utilitiy/buttons/ButtonBorderless';
import ButtonBack from '../../../utilitiy/buttons/ButtonBack';
import ButtonOutlined from '../../../utilitiy/buttons/ButtonOutlined';
import ButtonContained from '../../../utilitiy/buttons/ButtonContained';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  gridStyle: {
    padding: theme.spacing(1.5),
  },
}));

const SampleButtons: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant='h5'>Sample Buttons</Typography>
      <Grid container direction='column' justify='center' alignItems='flex-start'>
        {/* ButtonBorderless */}
        <Grid item className={classes.gridStyle}>
          <Typography variant='h6' >ButtonBorderless</Typography>
          <ButtonBorderless>Button</ButtonBorderless>
          &nbsp;
          <ButtonBorderless disabled>Button</ButtonBorderless>
        </Grid>

        {/* ButtonBack */}
        <Grid item className={classes.gridStyle}>
          <Typography variant='h6' >ButtonBack</Typography>
          <ButtonBack />
        </Grid>

        {/* ButtonOutlined */}
        <Grid item className={classes.gridStyle}>
          <Typography variant='h6' >ButtonOutlined</Typography>
          <ButtonOutlined buttonVariant='normal'>normal</ButtonOutlined>
          &nbsp;
          <ButtonOutlined buttonVariant='reminder'>Reminder</ButtonOutlined>
          &nbsp;
          <ButtonOutlined buttonVariant='important'>important</ButtonOutlined>
          &nbsp;
          <ButtonOutlined buttonVariant='success'>success</ButtonOutlined>
        </Grid>

        {/* ButtonContained */}
        <Grid item className={classes.gridStyle}>
          <Typography variant='h6' >ButtonContained</Typography>
          <ButtonContained buttonVariant='normal'>normal</ButtonContained>
          &nbsp;
          <ButtonContained buttonVariant='reminder'>Reminder</ButtonContained>
          &nbsp;
          <ButtonContained buttonVariant='important'>important</ButtonContained>
          &nbsp;
          <ButtonContained buttonVariant='success'>success</ButtonContained>
        </Grid>
      </Grid>
    </div>
  );
};

export default SampleButtons;
