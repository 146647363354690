import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, IconButton, TextField } from '@material-ui/core';
import { DataGrid, GridColDef,
  GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarColumnsButton,
} from '@material-ui/data-grid';
import { escapeRegExp } from '../../../helpers/formHelpers';
import { Search as SearchIcon, Clear as ClearIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  // ...theme.commonStyle(),
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  icon: {
    color: theme.customPalette.default.light,
    "&:hover": {
      color: theme.customPalette.default.accent,
    },
  },
  table: {
    color: theme.customPalette.extended.grey[5],
    borderColor: theme.customPalette.extended.grey[50],
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& label.Mui-focused': {
      color: theme.customPalette.default.accent,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.customPalette.default.accent,
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

interface DataTableInterface {
  columns: GridColDef[];
  data: any[];
  autoHeight?: boolean;
  disableColumnMenu?: boolean;
  disableSelectionOnClick?: boolean;
  rowsPerPageOptions?: Array<number>;
}

const DataTable: React.FC<DataTableInterface> = ({
  columns,
  data,
  autoHeight = true,
  disableColumnMenu = true,
  disableSelectionOnClick = true,
  rowsPerPageOptions=[10, 20, 50, 100],
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState<number>(rowsPerPageOptions[0]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [rows, setRows] = useState<any[]>(data);

  const handlePageSizeChange = (page: number) => {
    setPageSize(page);
  };

  useEffect(() => {
    setRows(data);
  }, [data]);

  const requestSearch = (searchValue: string) => {
    setSearchQuery(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <DataGrid
      className={classes.table}
      components={{ Toolbar: QuickSearchToolbar }}
      rows={rows}
      columns={columns}
      componentsProps={{
        toolbar: {
          value: searchQuery,
          onChange: (event: any) => requestSearch(event.target.value),
          clearSearch: () => requestSearch(''),
        },
      }}
      disableSelectionOnClick={disableSelectionOnClick}
      autoHeight={autoHeight}
      disableColumnMenu={disableColumnMenu}
      rowsPerPageOptions={rowsPerPageOptions}
      pageSize={pageSize}
      onPageSizeChange={handlePageSizeChange}
    />
  );
};

export default DataTable;

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

const QuickSearchToolbar: React.FC<QuickSearchToolbarProps> = ({
  clearSearch,
  onChange,
  value,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <GridToolbarColumnsButton className={classes.icon} />
        <GridToolbarFilterButton className={classes.icon} />
        <GridToolbarDensitySelector className={classes.icon} />
      </div>
      <TextField
        variant="standard"
        value={value}
        onChange={onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize='medium' />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};
