import React, { useState, useContext } from 'react';
import { makeStyles, Theme, Typography,
  Table, TableHead, TableBody, TableRow, TableCell,
  IconButton, Tooltip,
} from '@material-ui/core';
import ContextApp from '../../../../../context/ContextApp';
import useFetchProfile from './hooks/useFetchProfile';
import ChangePasswordDialog from '../../components/ChangePassword/ChangePasswordDialog';
import SettingsEditDialog, { SettingsUserFormDialogInterface } from './SettingsEditDialog';
import { CommonIcon } from '../../../../../helpers/IconManager';

const useStyles = makeStyles((theme: Theme) => ({
  root: {

  },
  tableHead: {
    fontWeight: 'bold',
  },
}));

const SettingsProfile: React.FC = () => {
  const classes = useStyles();
  const { authState } = useContext(ContextApp);
  const profile = useFetchProfile(authState._id);
  const [changePasswordDialog, setChangePasswordDialog] = useState({
    isOpen: false,
    selectedUserId: '',
  });
  const [settingsUserFormDialog, setSettingsUserFormDialog] = useState<SettingsUserFormDialogInterface>({
    isOpen: false,
    selectedUserId: '',
    field: '',
    label: '',
    initialValue: '',
    type: '',
  });

  return (
    <div className={classes.root}>
      <Typography variant='h5'>Profile Settings</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} align='left'>Details</TableCell>
            <TableCell className={classes.tableHead} align='right'>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Username */}
          <TableRow>
            <TableCell align='left'>
              {profile?.username}
              <br />
              <Typography variant='caption'>username</Typography>
            </TableCell>
            <TableCell align='right'>
              {/* <IconButton>
                <Tooltip title='Edit'>
                  <CommonIcon.Edit />
                </Tooltip>
              </IconButton> */}
            </TableCell>
          </TableRow>
          {/* Number */}
          <TableRow>
            <TableCell align='left'>
              {profile?.phoneNumber || ''}
              <br />
              <Typography variant='caption'>Phone number</Typography>
            </TableCell>
            <TableCell align='right'>
              <IconButton
                onClick={() => {
                  setSettingsUserFormDialog({
                    isOpen: true,
                    selectedUserId: authState._id,
                    field: 'phoneNumber',
                    label: 'Phone number',
                    initialValue: profile?.phoneNumber || '',
                    type: 'number',
                  });
                }}
              >
                <Tooltip title='Edit'>
                  <CommonIcon.Edit />
                </Tooltip>
              </IconButton>
            </TableCell>
          </TableRow>
          {/* Password */}
          <TableRow>
            <TableCell align='left'>
              ***************************
              <br />
              <Typography variant='caption'>Password</Typography>
            </TableCell>
            <TableCell align='right'>
              <IconButton
                onClick={() => {
                  setChangePasswordDialog({
                    isOpen: true,
                    selectedUserId: authState._id,
                  });
                }}
              >
                <Tooltip title='Change Password'>
                  <CommonIcon.Edit />
                </Tooltip>
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <ChangePasswordDialog
        changePasswordDialog={changePasswordDialog}
        setChangePasswordDialog={setChangePasswordDialog}
        requireOldPassword={true}
      />
      <SettingsEditDialog
        settingsUserFormDialog={settingsUserFormDialog}
        setSettingsUserFormDialog={setSettingsUserFormDialog}
      />
    </div>
  );
};

export default SettingsProfile;
