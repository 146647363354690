import React from 'react';
import { makeStyles, Theme, TextField } from '@material-ui/core';
import {  } from '@material-ui/lab';
import { Autocomplete, AutocompleteRenderInputParams, AutocompleteProps } from 'formik-material-ui-lab';

const useStyles = makeStyles((theme: Theme) => ({
  ...theme.commonStyle(),
  root: {
    width: '100%',
  },
}));

interface FormSelectMultipleInterface extends AutocompleteProps<string, true, true, true> {
  // onChange?: any;
  isrequired?: boolean;
  label: string,
}


const FormSelect = (props: FormSelectMultipleInterface) => {
  const classes = useStyles();
  const { isrequired, ...rest } = props;
  const label = isrequired ? `${props.label} *` : props.label;
  // console.log(rest)
  // console.log(rest.options);
  return (
    <div className={classes.root}>
      <Autocomplete
        {...rest}
        getOptionLabel={(options: any) => options.label ? options.label : ''}
        getOptionSelected={(option: any, value: any) => {
          if (value === '') return true;
          return option.value === value.value
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            className={classes.textfield}
            {...params}
            error={props.form.touched[rest.field.name] && !!props.form.errors[rest.field.name]}
            helperText={props.form.touched[rest.field.name] && props.form.errors[rest.field.name]}
            label={label}
            variant="standard"
          />
        )}
      />
    </div>
  );
};

export default FormSelect;
