import React from 'react';
import { makeStyles, Theme, Grid, Paper, MenuItem } from '@material-ui/core';
import { Route, Routes, Link } from 'react-router-dom';
import SampleTypography from './components/SampleTypography';
import SampleButtons from './components/SampleButtons';
import SampleDialog from './components/SampleDialog';
import SampleForm from './components/SampleForm';
import SampleTable from './components/SampleTable';

const useStyles = makeStyles((theme: Theme) => ({
  ...theme.commonStyle(),
  leftPanel: {
    width: '100%',
  },
  rightPanel: {
    backgroundColor: theme.customPalette.default.primary,
  },
}));

const SampleViewer = (): any => {
  const classes = useStyles();

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Grid container>
        <Grid item xs={2}>
          <Paper className={classes.leftPanel}>
            <Link to='typography' className={classes.routerLink}>
              <MenuItem className={classes.leftPanel}>
                Typography
              </MenuItem>
            </Link>
            <Link to='buttons' className={classes.routerLink}>
              <MenuItem className={classes.leftPanel}>
                Buttons
              </MenuItem>
            </Link>
            <Link to='dialog' className={classes.routerLink}>
              <MenuItem className={classes.leftPanel}>
                Dialog
              </MenuItem>
            </Link>
            <Link to='form' className={classes.routerLink}>
              <MenuItem className={classes.leftPanel}>
                Form
              </MenuItem>
            </Link>
            <Link to='table' className={classes.routerLink}>
              <MenuItem className={classes.leftPanel}>
                Table
              </MenuItem>
            </Link>
          </Paper>
        </Grid>
        <Grid item xs={10}>
          <div className={classes.rightPanel}>
            <Routes>
              <Route
                path='typography'
                element={<SampleTypography />}
              />
              <Route
                path='buttons'
                element={<SampleButtons />}
              />
              <Route
                path='dialog'
                element={<SampleDialog />}
              />
              <Route
                path='form'
                element={<SampleForm />}
              />
              <Route
                path='table'
                element={<SampleTable />}
              />
            </Routes>
          </div>
        </Grid>
      </Grid>
    </div>
  )
};

export default SampleViewer;
