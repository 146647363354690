import React from 'react';
import { Form, Field } from 'formik';
import * as Yup from 'yup';
import DialogForm from '../../../../utilitiy/dialog/DialogForm'
import FormTextField from '../../../../utilitiy/forms/FormTextField';
import { userPatch } from '../../../../../Feathers/Services/UserService'

export interface SettingsUserFormDialogInterface {
  isOpen: boolean;
  selectedUserId: string;
  field: string;
  label: string;
  initialValue: string;
  type: 'number' | 'text' | '';
}

interface SettingsEditDialogInterface {
  settingsUserFormDialog: SettingsUserFormDialogInterface;
  setSettingsUserFormDialog: any;
}

const SettingsEditDialog: React.FC<SettingsEditDialogInterface> = ({
  settingsUserFormDialog,
  setSettingsUserFormDialog,
}) => {
  const fieldValidationRequiredMessage = `${settingsUserFormDialog.label} is required`;

  const handleCancel = () => {
    setSettingsUserFormDialog({
      isOpen: false,
      selectedUserId: '',
      field: '',
      label: '',
      initialValue: '',
    });
  }

  return (
    <DialogForm
      open={settingsUserFormDialog.isOpen}
      title={`Edit ${settingsUserFormDialog.label}`}
      variant='normal'
      initialValues={{
        [settingsUserFormDialog.field]: settingsUserFormDialog.initialValue || '',
      }}
      validationSchema={{
        [settingsUserFormDialog.field]: settingsUserFormDialog.type === 'text' ? Yup.string().required(fieldValidationRequiredMessage) : Yup.number().typeError("Must be a number."),
      }}
      onSubmit={async (values, actions) => {
        await userPatch(settingsUserFormDialog.selectedUserId, values);
        actions.setSubmitting(false);
        handleCancel();
      }}
      onCancel={() => handleCancel()}
    >
      <div style={{ width: '500px' }}>
        <Form>
          {
            settingsUserFormDialog.field !== '' ? (
              <Field
                component={FormTextField}
                name={settingsUserFormDialog.field}
                type='text'
                label={settingsUserFormDialog.label}
                isrequired
              />
            ) : null
          }
        </Form>
      </div>
    </DialogForm>
  );
};

export default SettingsEditDialog;
