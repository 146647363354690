import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import getAppThemeColor from './ThemeColor';

const getAppThemeFont = () => {
  const customPalette = getAppThemeColor();

  return {
    fontFamily: '"Open Sans", "Roboto", "Helvetica", sans-serif',
    h1: {
      fontSize: '3.375rem',
      letterSpacing: 0,
      lineHeight: '4rem',
      fontWeight: 300,
      color: customPalette.default.accent,
    },
    h2: {
      fontSize: '2.625rem',
      letterSpacing: 0,
      lineHeight: '3.125rem',
      fontWeight: 300,
      color: customPalette.default.accent,
    },
    h3: {
      fontSize: '2rem',
      letterSpacing: 0,
      lineHeight: '2.4rem',
      fontWeight: 400,
      color: customPalette.default.accent,
    },
    h4: {
      fontSize: '1.75rem',
      letterSpacing: 0,
      lineHeight: '2.25rem',
      fontWeight: 400,
      color: customPalette.default.accent,
    },
    h5: {
      fontSize: '1.375rem',
      letterSpacing: 0,
      lineHeight: '1.875rem',
      fontWeight: 700,
      color: customPalette.default.accent,
    },
    h6: {
      fontSize: '1rem',
      letterSpacing: 0,
      lineHeight: '1.375rem',
      fontWeight: 700,
      color: customPalette.default.accent,
    },
    body1: {
      fontSize: '1rem',
      letterSpacing: 0,
      lineHeight: '1.375rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      letterSpacing: 0,
      lineHeight: '1.125rem',
      fontWeight: 400,
      color: customPalette.extended.grey[40],
    },
    subtitle1: {
      fontSize: '1rem',
      letterSpacing: 0,
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '0.875rem',
      letterSpacing: 0,
      lineHeight: '1.125rem',
      fontWeight: 400,
      color: customPalette.extended.grey[40],
    },
    caption: {
      fontSize: '0.75rem',
      letterSpacing: 0,
      lineHeight: '1.125rem',
      fontWeight: 400,
      color: customPalette.extended.grey[40],
    },
  } as TypographyOptions;
}

export default getAppThemeFont;
