import React, { useContext, useState } from 'react';
import { makeStyles, Theme, Typography,
  Table, TableHead, TableBody, TableRow, TableCell,
  IconButton, Tooltip,
} from '@material-ui/core';
import ContextApp from '../../../../../context/ContextApp';
import { CommonIcon } from '../../../../../helpers/IconManager';
import useFetchCompany from './hooks/useFetchCompany';
import SettingsCompanyEditDialog, { SettingsCompanyEditDialogFormInterface } from './components/SettingsCompanyEditDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {

  },
  tableHead: {
    fontWeight: 'bold',
  },
}));

const SettingsCompany: React.FC = () => {
  const classes = useStyles();
  const { authState } = useContext(ContextApp);
  const company = useFetchCompany(authState.company._id || '');
  const [companyEditDialog, setCompanyEditDialog] = useState<SettingsCompanyEditDialogFormInterface>({
    isOpen: false,
    companyId: '',
    label: authState.company._id || '',
    field: '',
    initialValue: '',
    type: 'text'
  });

  const rows = [{
    field: 'address',
    label: 'Address',
  }, {
    field: 'email',
    label: 'Email',
  }, {
    field: 'phoneNumber',
    label: 'Phone Number',
  }];

  const getKeyValue = function<T extends object, U extends keyof T> (obj: T, key: U) { return obj[key] }

  const generateRows = company ? rows.map((row: any) => (
    <TableRow key={row.field}>
      <TableCell align='left'>
        {getKeyValue(company, row.field)}
        <br />
        <Typography variant='caption'>{row.label}</Typography>
      </TableCell>
      <TableCell align='right'>
        <IconButton
          onClick={() => {
            setCompanyEditDialog({
              isOpen: true,
              companyId: company._id,
              label: row.label,
              field: row.field,
              initialValue: getKeyValue(company, row.field),
              type: 'text',
            });
          }}
        >
          <Tooltip title='Edit'>
            <CommonIcon.Edit />
          </Tooltip>
        </IconButton>
      </TableCell>
    </TableRow>
  )) : null;

  return (
    <div className={classes.root}>
      <Typography variant='h5'>Company Settings</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} align='left'>Details</TableCell>
            <TableCell className={classes.tableHead} align='right'>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {generateRows}
        </TableBody>
      </Table>
      <SettingsCompanyEditDialog
        companyEditDialog={companyEditDialog}
        setCompanyEditDialog={setCompanyEditDialog}
      />
    </div>
  );
};

export default SettingsCompany;
