import extendedPalette from './ExtendedPalette';

export const paletteDefault = {
  accent: extendedPalette.accent[50],
  primary: extendedPalette.grey[100],
  secondary: extendedPalette.grey[95],
  highlight: extendedPalette.grey[90],
  highlightNegative: extendedPalette.grey[80],
  light: extendedPalette.grey[10],
  dark: extendedPalette.grey[100],
  yellow: extendedPalette.yellow[50],
  amber: extendedPalette.amber[50],
  red: extendedPalette.red[50],
  purple: extendedPalette.purple[50],
  blue: extendedPalette.blue[50],
  torquoise: extendedPalette.torquoise[50],
  green: extendedPalette.green[50],
  grey: extendedPalette.grey[50],
}

export default function getAppThemeColor(): any {
  const colorConfig: typeof paletteDefault = paletteDefault;

  return {
    default: colorConfig,
    extended: extendedPalette,
    status: {
      normal: colorConfig.accent,
      important: colorConfig.red,
      success: colorConfig.green,
      reminder: colorConfig.yellow,
    },
    dialog: {
      normal: colorConfig.accent,
      important: colorConfig.red,
      success: colorConfig.green,
      reminder: colorConfig.yellow,
    },
    text: {
      primary: extendedPalette.grey[10],
      secondary: extendedPalette.grey[35],
      interactive: extendedPalette.accent[50],
      placeholder: extendedPalette.grey[60],
      hint: extendedPalette.grey[60],
    },
    appbar: {
      bodyBackground: colorConfig.secondary,
      statusElementBackground: colorConfig.highlight,
    },
    common: {
      searchBarBackground: colorConfig,
      dividerLine: colorConfig.highlightNegative,
      leftPanelBackground: colorConfig.primary,
    },
    card: {
      bodyBackground: colorConfig.secondary,
      titleText: colorConfig.accent,
      elementBackground: colorConfig.highlight,
      detailText: colorConfig.light,
      detailIcon: colorConfig.grey[40],
    },
    button: {
      fab: colorConfig.accent,
      collapse: colorConfig.light,
      moreOption: colorConfig.light,
      filterMultiple: {
        background: colorConfig.secondary,
        boder: colorConfig.secondary,
        text: colorConfig.light,
        uncheck: colorConfig.grey[40],
        checked: colorConfig.accent,
        hover: colorConfig.accent,
      },
      toggle: {
        background: colorConfig.secondary,
        text: colorConfig.light,
        border: colorConfig.secondary,
        hover: {
          border: colorConfig.accent,
        },
        checked: {
          border: colorConfig.accent,
        },
        focus: {
          border: colorConfig.accent,
        },
      },
    },
    form: {
      title: extendedPalette.grey[35],
      background: extendedPalette.grey[85],
      backgroundFocus: extendedPalette.grey[85],
      textFocus: colorConfig.light,
      backgroundActive: extendedPalette.grey[85],
      textActive: colorConfig.light,
      placeholder: extendedPalette.grey[35],
      error: colorConfig.red,
      subtitle: colorConfig.light,
      text: colorConfig.light,
      inputIcon: colorConfig.light,
      border: colorConfig.primary,
      buttonText: colorConfig.accent,
    },
    states: {
      selected: colorConfig.accent,
      focus: colorConfig.accent,
      hover: colorConfig.accent,
      checked: colorConfig.accent,
    }
  }
}
