import React from 'react';
import { makeStyles, Theme, ButtonProps } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import ButtonBorderless from './ButtonBorderless';
import { CommonIcon } from '../../../helpers/IconManager';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.customPalette.form.text,
    width: 100,
    textTransform: 'capitalize',
  },
  buttonDiv: {
    display: 'flex',
    width: 80,
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }
}));

const ButtonBack: React.FC = ({ ...others }: ButtonProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <ButtonBorderless {...others} onClick={() => navigate(-1)}>
      <div className={classes.buttonDiv}>
        <CommonIcon.ArrowLeft />
        Back
      </div>
    </ButtonBorderless>
  );
};

export default ButtonBack;
