import React from 'react';
// import { makeStyles, Theme, FormControlLabel } from '@material-ui/core';
import { Switch, SwitchProps } from 'formik-material-ui';

// const useStyles = makeStyles((theme: Theme) => ({
//   ...theme.commonStyle(),
// }));

interface FormSwitchInterface extends SwitchProps {
  label: string;
}

const FormSwitch = (props: FormSwitchInterface) => {
  return (
    <>
      {props.label}:
      &nbsp;
      <Switch
        {...props}
      />
    </>
  );
};

export default FormSwitch;
