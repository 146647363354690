import React from 'react';
import { Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { StatusType, useStatusStyles } from '../../../theme/ThemeStatus';
import DialogTitle from './DialogTitle';
import ButtonOutlined from '../buttons/ButtonOutlined'
import ButtonContained from '../buttons/ButtonContained'

interface DialogAlertInterface {
  open: boolean;
  title: string;
  variant?: keyof typeof StatusType;
  onAcknowledge: () => void;
  onConfirm?: () => void;
  error?: any;
  submitTitle?: string;
  zIndex?: number;
}

const DialogAlert: React.FC<DialogAlertInterface> = ({
  open,
  title,
  variant = 'normal',
  children,
  onAcknowledge,
  onConfirm,
  error,
  submitTitle = 'Acknowledge',
  zIndex,
}) => {
  const classes = useStatusStyles();
  const displayError = error ? (
    <Typography
      className={classes.dialogError}
      variant='subtitle2'
    >
        Error ({error.code}): {error.message}
    </Typography>
  ) : null;
  
  const displayDialogActionButtons = onConfirm ? (
    <>
      <ButtonOutlined
        buttonVariant={variant}
        onClick={onAcknowledge}
      >
        Cancel
      </ButtonOutlined>
      &nbsp;
      <ButtonContained
        buttonVariant={variant}
        onClick={onConfirm}
      >
        {submitTitle}
      </ButtonContained>
    </>
  ) : (
    <ButtonOutlined
      buttonVariant={variant}
      onClick={onAcknowledge}
    >
      {submitTitle}
    </ButtonOutlined>
  );

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      classes={{ paper: `${classes.dialogPaper} ${classes[variant]}` }}
      style={zIndex ? { zIndex } : {}}
    >
      <DialogTitle variant={variant}>{title}</DialogTitle>
      <DialogContent>
        {displayError}
        {children}
      </DialogContent>
      <DialogActions>
        { displayDialogActionButtons }
        {/* <ButtonOutlined
          buttonVariant={variant}
          onClick={onAcknowledge}
        >
          Acknowledge
        </ButtonOutlined> */}
      </DialogActions>
    </Dialog>
  );
};

export default DialogAlert;
