import { useEffect, useState } from 'react';
import { workplaceFeathersService, workplaceFindByCompany } from '../../../../../../Feathers/Services/WorkplaceService';
import { Workspace } from '../../../../../../server-interface';

const useFetchCompanyWorkspace = (companyId: string): any => {
  const [workplaces, setWorkplaces] = useState<Workspace[]>([]);

  // Init
  useEffect(() => {
    const init = async () => {
      const fetchedWorkplaces = await workplaceFindByCompany(companyId);
      setWorkplaces(fetchedWorkplaces);
    }
    init();
  }, [companyId]);

  // Listener
  useEffect(() => {
    workplaceFeathersService.on('created', (createdWorkplace: Workspace) => {
      setWorkplaces((prevState: Workspace[]) => [...prevState, createdWorkplace]);
    });

    workplaceFeathersService.on('patched', (patchedWorkplace: Workspace) => {
      setWorkplaces((prevState: Workspace[]) => prevState.map((workplace: Workspace) => {
        if (workplace._id === patchedWorkplace._id) return patchedWorkplace;
        return workplace;
      }));
    });

    workplaceFeathersService.on('removed', (removedWorkplace: Workspace) => {
      setWorkplaces((prevState: Workspace[]) => prevState.filter((
        workplace: Workspace) => workplace._id !== removedWorkplace._id));
    })

    return () => {
      workplaceFeathersService.removeAllListeners('created');
      workplaceFeathersService.removeAllListeners('patched');
      workplaceFeathersService.removeAllListeners('removed');
    }
  }, []);

  return workplaces;
}

export default useFetchCompanyWorkspace;
