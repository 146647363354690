import { Paginated } from '@feathersjs/feathers';
import FeathersApp, { Service } from '../Feathers';
import { Company } from '../../server-interface';

export const companyFeathersService: any = FeathersApp.service(Service.Company);

export const companyFindPaginated = (
  skip: number,
): Promise<Paginated<Company>> => (
  companyFeathersService.find({
    query: {
      $skip: skip,
      $sort: {
        'name': 1,
      },
    }
  })
);

export const companyGet = (id: string): Promise<Company> => (
  companyFeathersService.get({ _id: id })
);

export const companyCreate = (data: Company): Promise<Company> => (
  companyFeathersService.create(data)
);

export const companyPatch = (
  id: string,
  data: Partial<Company>,
): Promise<Company> => (
  companyFeathersService.patch(id, data)
);
