import React from 'react';
import { makeStyles, Theme, Grid, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormTextField from '../../../utilitiy/forms/FormTextField';
import FormSwitch from '../../../utilitiy/forms/FormSwitch';
import FormSelectSingle from '../../../utilitiy/forms/FormSelectSingle';
import FormSelect from '../../../utilitiy/forms/FormSelect';
import FormTagsFreeSolo from '../../../utilitiy/forms/FormTagsFreeSolo';
import ButtonContained from '../../../utilitiy/buttons/ButtonContained';
import ButtonOutlined from '../../../utilitiy/buttons/ButtonOutlined';

import FormDateField from '../../../utilitiy/forms/FormDateField';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  gridStyle: {
    padding: theme.spacing(1.5),
  },
}));

const SampleForm: React.FC = () => {
  const classes = useStyles();

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    // shelveId: Yup.string().required('Shelve is required.'),
    quantity: Yup.number().typeError("Must be a number.")
      .positive('Negative numbers not allowed.').integer('Must be a whole number').required("Quantity is required."),
    choices: Yup.array().required('Choices is required'),
  });

  const fakeOptions = [
    { value: '1', label: 'Item 1' },
    { value: '2', label: 'Item 2' },
    { value: '3', label: 'Item 3' },
    { value: '4', label: 'Item 4' },
  ];

  return (
    <div className={classes.root}>
      <Typography variant='h5'>Sample Form</Typography>
      <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
        <Grid item className={classes.gridStyle}>
          <Formik
            enableReinitialize
            validationSchema={schema}
            initialValues={{
              name: '',
              description: '',
              quantity: '',
              remember: true,
              options: '1',
              choices: [{ value: '1', label: 'Item 1' }, { value: '3', label: 'Item 3' }],
              choiceSingle: { value: '3', label: 'Item 3' },
              choicesFreeSolo: ['hello'],
              eventDate: new Date(),
            }}
            onSubmit={(values, actions) => {
              console.log(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              isSubmitting,
              errors,
              submitForm,
              dirty,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setTouched,
              resetForm,
            }) => (
              <Form>
                <Typography variant='h6' >Form Title</Typography>
                <Field
                  component={FormTextField}
                  name='name'
                  type='text'
                  label='Name'
                  isrequired
                />
                <Field
                  component={FormTextField}
                  name='description'
                  type='text'
                  label='Description'
                  multiline
                  minRows={5}
                  maxRows={3}
                />
                <Field
                  component={FormTextField}
                  name='quantity'
                  type='text'
                  label='Quantity'
                  isrequired
                />
                <Field
                  component={FormSwitch}
                  name='remember'
                  type='checkbox'
                  label='Remember me'
                />
                <Field
                  component={FormSelectSingle}
                  name='options'
                  type='select'
                  options={fakeOptions}
                  label='Which one'
                />
                <Field
                  component={FormSelect}
                  name='choices'
                  options={fakeOptions}
                  label='Choices Multiple'
                  multiple
                  isrequired
                />
                <Field
                  component={FormSelect}
                  name='choiceSingle'
                  options={fakeOptions}
                  label='Choices Single'
                  isrequired
                />
                <Field
                  component={FormTagsFreeSolo}
                  name='choicesFreeSolo'
                  label='Choices Free Solo'
                  isrequired
                />
                <Field
                  component={FormDateField}
                  name='eventDate'
                  type='date'
                  label='Event Date'
                  isrequired
                />
                <ButtonOutlined
                  buttonVariant='normal'
                  style={{width: '98%'}}
                  onClick={() => resetForm()}
                >
                  Cancel
                </ButtonOutlined>
                <ButtonContained
                  buttonVariant='normal'
                  type='submit'
                  onClick={() => submitForm()}
                  disabled={isSubmitting || !dirty || (Object.keys(errors).length > 0)}
                >
                  Submit
                </ButtonContained>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
};

export default SampleForm;
