import React from 'react';
import { Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { StatusType, useStatusStyles } from '../../../theme/ThemeStatus';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DialogTitle from './DialogTitle';
import ButtonOutlined from '../buttons/ButtonOutlined'
import ButtonContained from '../buttons/ButtonContained'

interface DialogFormInterface {
  open: boolean;
  title: string;
  variant?: keyof typeof StatusType;
  initialValues: any;
  validationSchema: any;
  onCancel: () => void;
  onSubmit: (values?: any, actions?: any) => void;
  allowNotDirtySubmit?: boolean;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  submitDisabled?: boolean;
  error?: any;
  zIndex?: number;
}

const DialogForm: React.FC<DialogFormInterface> = ({
  open,
  title,
  variant = 'normal',
  children,
  initialValues,
  validationSchema,
  onCancel,
  onSubmit,
  allowNotDirtySubmit,
  fullWidth = false,
  maxWidth = 'lg',
  submitDisabled = false,
  error,
  zIndex,
}) => {
  const classes = useStatusStyles();

  const dirtyCheck = (dirty: boolean) => {
    if (allowNotDirtySubmit) return false;
    return !dirty;
  }

  const displayError = error ? (
    <Typography
      className={classes.dialogError}
      variant='subtitle2'
    >
        Error ({error.code}): {error.message}
    </Typography>
  ) : null;

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      classes={{ paper: `${classes.dialogPaper} ${classes[variant]}` }}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      style={zIndex ? { zIndex } : {}}
    >
        <Formik
          validationSchema={Yup.object().shape(validationSchema)}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            onSubmit(values, actions);
          }}
          enableReinitialize
        >
          {({
            errors,
            isSubmitting,
            dirty,
            submitForm,
          }) => (
            <>
              <DialogTitle variant={variant}>{title}</DialogTitle>
              <DialogContent>
                {displayError}
                {children}
              </DialogContent>
              <DialogActions>
                <ButtonOutlined
                  buttonVariant={variant}
                  onClick={onCancel}
                >
                  Cancel
                </ButtonOutlined>
                &nbsp;
                <ButtonContained
                  buttonVariant={variant}
                  onClick={submitForm}
                  disabled={submitDisabled || isSubmitting || dirtyCheck(dirty) || (Object.keys(errors).length > 0)}
                >
                  Submit
                </ButtonContained>
              </DialogActions>
            </>
          )}
        </Formik>
    </Dialog>
  );
};

export default DialogForm;
