import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import SettingsWorkplaceList from './SettingsWorkplaceList';
import PageTitle from '../../../../utilitiy/PageTitle';
import ButtonOutlined from '../../../../utilitiy/buttons/ButtonOutlined';
import SettingsWorkplaceForm from './SettingsWorkplaceForm';
import { Workspace } from '../../../../../server-interface';

const useStyles = makeStyles((theme: Theme) => ({
  root: {

  },
}));

interface WorkspaceFormInfo {
  isOpen: boolean;
  selectedWorkspace: Partial<Workspace> | undefined;
}

const SettingsWorkspace: React.FC = () => {
  const classes = useStyles();
  const [workspaceFormInfo, setWorkspaceFormInfo] = useState<WorkspaceFormInfo>({
    isOpen: false,
    selectedWorkspace: undefined,
  });

  return (
    <div className={classes.root}>
      <PageTitle
        title='Workplace Settings'
        rightIcon={(
          <ButtonOutlined
            buttonVariant='normal'
            onClick={() => setWorkspaceFormInfo({ isOpen: true, selectedWorkspace: undefined })}
          >
            Add New
          </ButtonOutlined>
        )}
      />
      <SettingsWorkplaceList
        setWorkspaceFormInfo={setWorkspaceFormInfo}
      />
      <SettingsWorkplaceForm
        workspaceFormInfo={workspaceFormInfo}
        setWorkspaceFormInfo={setWorkspaceFormInfo}
      />
    </div>
  );
};

export default SettingsWorkspace;
