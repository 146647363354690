// import { Hook, HookContext } from '@feathersjs/feathers';

// const logError: Hook = (context: HookContext) => {
//   console.log(context);
// }

const checkError = (context: any) => {
  const { error } = context;
  if (error.code === 401) window.location.replace(process.env.REACT_APP_APPCLIENT_URL || "https://japlauto.com/login");
  return context;
};

const hooks = {
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },

  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },

  error: {
    all: [checkError],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};

export default hooks;