import React, { useState } from 'react';
import { makeStyles, Theme, Tabs, Tab, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: '100%', overflowY: 'auto' }}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '90vh',
    marginTop: theme.spacing(1),
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

interface SettingsPagesInterface {
  title: string;
  component: React.ReactElement;
}

interface SettingsInterface {
  pages: SettingsPagesInterface[]
}

const Settings: React.FC<SettingsInterface> = ({
  pages,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  
  const tabs = pages.map((page: SettingsPagesInterface, key: number) => (
    <Tab key={key} label={page.title} {...a11yProps(key)} />
  ));

  const tabPanels = pages.map((page: SettingsPagesInterface, key: number) => (
    <TabPanel key={key} value={value} index={key}>
      { page.component }
    </TabPanel>
  ));

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {tabs}
      </Tabs>
      {tabPanels}
    </div>
  );
};

export default Settings;
