export default function getAppThemeVariables() {
  return {
    appBar: {
      height: 64,
      appIcon: {
        width: 64,
      }
    },
    drawer: {
      left: {
        width: 500,
      },
      right: {
        width: 380,
      },
    },
    radioCheckBox: {
      height: 18,
      width: 18,
    },
    dialog: {
      minWidth: 500,
    },
    form: {
      elementMinHeight: 30,
      fontSize: '1rem',
      placeholderFontSize: '1rem',
    },
    common: {
      borderRadius: 6,
      iconButtonWidth: 50,
      titleContainerHeight: 60,
    }
  }
}
