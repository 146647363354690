const extendedPalette = {
  yellow: {
    80: '#FACA00',
    70: '#FFD908',
    60: '#FFE233',
    50: '#FEE75A',
    40: '#FEEB78',
    30: '#FEEF98',
    20: '#FEF484',
    10: '#FEF8D2',
  },
  amber: {
    80: '#E87B1E',
    70: '#F88826',
    60: '#FF9527',
    50: '#FD9F37',
    40: '#FDB05B',
    30: '#FDC17F',
    20: '#FED3A4',
    10: '#FEE4C8',
  },
  red: {
    80: '#A72421',
    70: '#BE3532',
    60: '#D84B47',
    50: '#FC695B',
    40: '#FC7C78',
    30: '#FD9996',
    20: '#FDB6B4',
    10: '#FED3D2',
  },
  purple: {
    80: '#4D48EF',
    70: '#5550FD',
    60: '#615CFC',
    50: '#6F6AFF',
    40: '#8985FF',
    30: '#A3A8FF',
    20: '#BDBBFF',
    10: '#D7D6FF',
  },
  blue: {
    80: '#005FF8',
    70: '#0077F8',
    60: '#1A88FF',
    50: '#3998FF',
    40: '#5DAAFF',
    30: '#81BDFF',
    20: '#A5D0FF',
    10: '#C9E2FF',
  },
  // accent: {
  //   80: '#0094B7',
  //   70: '#00A6CE',
  //   60: '#00C3F2',
  //   50: '#00DCFF',
  //   40: '#2EE2FF',
  //   30: '#5CE8FF',
  //   20: '#8BEFFF',
  //   10: '#B9F5FF',
  // },
  // https://colorswall.com/palette/13/
  accent: {
    80: '#038BE5',
    70: '#03A9F4',
    60: '#29B6F6',
    50: '#77ddfc',
    40: '#4FC3F7',
    30: '#81D4FA',
    20: '#B3E5FC',
    10: '#E1F5FE',
  },
  // accent: {
  //   80: '#341777',
  //   70: '#411D94',
  //   60: '#4E23B2',
  //   50: '#5B29D0',
  //   40: '#7044D9',
  //   30: '#8761DF',
  //   20: '#937FE5',
  //   10: '#B49DEB',
  // },
  torquoise: {
    80: '#00A780',
    70: '#00BF93',
    60: '#00D0A0',
    50: '#00E3AF',
    40: '#2EE8BD',
    30: '#5CEDCC',
    20: '#8BF2DA',
    10: '#B9F7E9',
  },
  green: {
    80: '#39B21A',
    70: '#48C429',
    60: '#52DB31',
    50: '#6BE84D',
    40: '#8BF672',
    30: '#A5F891',
    20: '#BEFAB0',
    10: '#D8FCD0',
  },
  grey: {
    100: '#121212',
    95: '#1C1C1C',
    90: '#262626',
    85: '#303030',
    80: '#3A3A3A',
    75: '#444444',
    70: '#4E4E4E',
    65: '#585858',
    60: '#626262',
    55: '#6C6C6C',
    50: '#747474',
    45: '#888888',
    40: '#9C9C9C',
    35: '#B0B0B0',
    30: '#C4C4C4',
    25: '#CECECE',
    20: '#D8D8D8',
    15: '#E2E2E2',
    10: '#ECECEC',
    5: '#F6F6F6',
  },
}

export default extendedPalette;
