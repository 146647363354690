import React, { ReactChild } from 'react';
import { makeStyles, Theme, Button, ButtonProps } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    color: theme.customPalette.extended.grey[40],
    height: 40,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.customPalette.default.highlight,
    },
    '&:active': {
      backgroundColor: theme.customPalette.default.accent,
      color: theme.customPalette.default.dark,
    },
    '&:focus': {
      backgroundColor: theme.customPalette.default.accent,
      color: theme.customPalette.default.dark,
    },
    '&:disabled': {
      backgroundColor: theme.customPalette.default.secondary,
      color: theme.customPalette.default.highlight,
    },
  },
}));

interface ButtonBorderlessInterface extends ButtonProps {
  children: ReactChild;
}

const ButtonBorderless = (props: ButtonBorderlessInterface) => {
  const classes = useStyles();
  const { children, ...buttonProps } = props;

  return (
    <Button
      classes={{ root: classes.root }}
      {...buttonProps}
    >
      { children }
    </Button>
  );
};

export default ButtonBorderless;
