import { useEffect, useState } from 'react';
import { userFeathersService, userFindByCompany } from '../../../../../../Feathers/Services/UserService';
import { User } from '../../../../../../server-interface';

const useFetchCompanyUsers = (companyId: string): any => {
  const [users, setUsers] = useState<User[]>([]);

  // Init
  useEffect(() => {
    const init = async () => {
      const fetchedUsers = await userFindByCompany(companyId);
      setUsers(fetchedUsers);
    }
    init();
  }, [companyId]);

  // Listener
  useEffect(() => {
    userFeathersService.on('created', (createdUser: User) => {
      setUsers((prevState: User[]) => [...prevState, createdUser]);
    });

    userFeathersService.on('patched', (patchedUser: User) => {
      setUsers((prevState: User[]) => prevState.map((user: User) => {
        if (user._id === patchedUser._id) return patchedUser;
        return user;
      }));
    });

    userFeathersService.on('removed', (removedUser: User) => {
      setUsers((prevState: User[]) => prevState.filter((user: User) => user._id !== removedUser._id));
    })

    return () => {
      userFeathersService.removeAllListeners('created');
      userFeathersService.removeAllListeners('patched');
      userFeathersService.removeAllListeners('removed');
    }
  }, []);

  return users;
}

export default useFetchCompanyUsers;

