import { createTheme, ThemeOptions } from '@material-ui/core';
import getAppThemeColor from './ThemeColor';
import getAppThemeFont from './ThemeFont';
import getAppThemeVariables from './ThemeVariables';
import getAppCommonStyle, { CommonStyleOptions } from './ThemeStyle';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    customPalette: ReturnType<typeof getAppThemeColor>;
    customVariable: ReturnType<typeof getAppThemeVariables>;
    commonStyle: () => CommonStyleOptions;
  }

  interface ThemeOptions {
    customPalette?: ReturnType<typeof getAppThemeColor>;
    customVariable?: ReturnType<typeof getAppThemeVariables>;
    commonStyle?: () => CommonStyleOptions;
  }
}

export const createAppTheme = (options: ThemeOptions) => {
  const customPalette = getAppThemeColor();
  const theme = createTheme({
    typography: getAppThemeFont(),
    spacing: 10,
    customVariable: getAppThemeVariables(),
    customPalette,
    ...options,
  });

  theme.commonStyle = () => getAppCommonStyle(theme);

  theme.overrides = {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.875',
      },
    },
    MuiInput: {
      input: {
        // backgroundColor: theme.customPalette.form.background,
        width: '100%',
        minHeight: theme.customVariable.form.elementMinHeight,
        height: '100%',
        paddingLeft: theme.spacing(),
        '&::placeholder': {
          color: theme.customPalette.form.placeholder,
        },
        '&::focus': {
          backgroundColor: theme.customPalette.form.backgroundFocus,
          color: theme.customPalette.form.textFocus,
        },
        '&::active': {
          backgroundColor: theme.customPalette.form.backgroundActive,
          color: theme.customPalette.form.textActive,
        },
      },
    },
  };

  return theme;
};

const nightTheme = createAppTheme({
  palette: {
    primary: {
      main: '#242424'
    },
    secondary: {
      main: '#77ddfc'
    },
    type: 'dark',
  },
});

const dayTheme = createAppTheme({
  palette: {
    primary: {
      main: '#242424'
    },
    secondary: {
      main: '#77ddfc'
    },
    type: 'light',
  },
});

export function getTheme(isNight: boolean) {
  // return dayTheme;
  return isNight ? nightTheme : dayTheme;
}
