import { useEffect, useState } from 'react';
import { userFeathersService, userGet } from '../../../../../../Feathers/Services/UserService'
import { User } from '../../../../../../server-interface';

const useFetchProfile = (userId: string) => {
  const [profile, setProfile] = useState<Partial<User>>();

  // Init
  useEffect(() => {
    const init = async () => {
      let fetchedProfile = {};
      if (userId) {
        fetchedProfile = await userGet(userId);
      }
      setProfile(fetchedProfile);
    }
    init();
  }, [userId]);

  // Listener
  useEffect(() => {
    const handlePatchUser = (patchedUser: Partial<User>) => {
      if (patchedUser._id === userId) setProfile(patchedUser);
    }

    userFeathersService.on('patched', handlePatchUser);

    return () => {
      userFeathersService.removeListener('patched', handlePatchUser);
    }
  }, [userId]);

  return profile;
}

export default useFetchProfile;
