import React, { useContext } from 'react';
import {
  makeStyles, Theme, Typography,
  Table, TableHead, TableBody, TableRow, TableCell,
  IconButton, Tooltip,
} from '@material-ui/core';
import ContextApp from '../../../../../context/ContextApp';
import { CommonIcon } from '../../../../../helpers/IconManager';
import useFetchCompanyWorkspace from './hooks/useFetchCompanyWorkspace'
import { Workspace } from '../../../../../server-interface';
import { User } from '../../../../../server-interface';

const useStyles = makeStyles((theme: Theme) => ({
  table: {

  },
  tableHead: {
    fontWeight: 'bold',
  },
}));

interface SettingsWorkplaceListInterface {
  setWorkspaceFormInfo: any;
}

const SettingsWorkplaceList: React.FC<SettingsWorkplaceListInterface> = ({
  setWorkspaceFormInfo,
}) => {
  const classes = useStyles();
  const { authState } = useContext(ContextApp);
  const workplaces = useFetchCompanyWorkspace(authState.company._id || '');

  const displayUsers = (users: any) => users.map((user: Partial<User>) => (
    <Typography key={user.username}>{user.username}</Typography>
  ))

  const displayWorkplaces = () => workplaces.map((workplace: Workspace) => (
    <TableRow key={workplace.name}>
      <TableCell>{workplace.name}</TableCell>
      <TableCell>{workplace.address}</TableCell>
      <TableCell>{workplace.phoneNumber}</TableCell>
      <TableCell>{displayUsers(workplace.workspaceMembers)}</TableCell>
      <TableCell>{displayUsers(workplace.workspaceAdmins)}</TableCell>
      <TableCell align='center'>
        <IconButton
          onClick={() => setWorkspaceFormInfo({
            isOpen: true,
            selectedWorkspace: workplace,
          })}
        >
          <Tooltip title='Edit'>
            <CommonIcon.Edit />
          </Tooltip>
        </IconButton>  
      </TableCell>
    </TableRow>
  ));

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHead} align='left'>Name</TableCell>
          <TableCell className={classes.tableHead} align='left'>Address</TableCell>
          <TableCell className={classes.tableHead} align='left'>Number</TableCell>
          <TableCell className={classes.tableHead} align='left'>Members</TableCell>
          <TableCell className={classes.tableHead} align='left'>Admins</TableCell>
          <TableCell className={classes.tableHead} align='center'>Edit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {displayWorkplaces()}
      </TableBody>
    </Table>
  );
};

export default SettingsWorkplaceList;
