import React from 'react';
import { Form, Field } from 'formik';
import * as Yup from 'yup';
import DialogForm from '../../../../../utilitiy/dialog/DialogForm'
import FormTextField from '../../../../../utilitiy/forms/FormTextField';
import { companyPatch } from '../../../../../../Feathers/Services/CompanyService';

export interface SettingsCompanyEditDialogFormInterface {
  isOpen: boolean;
  companyId: string;
  label: string;
  field: string;
  initialValue: string;
  type: 'number' | 'text' | '';
}

interface SettingsCompanyEditDialogInterface {
  companyEditDialog: SettingsCompanyEditDialogFormInterface;
  setCompanyEditDialog: any;
}

const SettingsCompanyEditDialog: React.FC<SettingsCompanyEditDialogInterface> = ({
  companyEditDialog,
  setCompanyEditDialog,
}) => {
  const fieldValidationRequiredMessage = `${companyEditDialog.label} is required`;

  const handleCancel = () => {
    setCompanyEditDialog({
      isOpen: false,
      companyId: '',
      field: '',
      label: '',
      initialValue: '',
    });
  }

  return (
    <DialogForm
      open={companyEditDialog.isOpen}
      title={`Edit ${companyEditDialog.label}`}
      initialValues={{
        [companyEditDialog.field]: companyEditDialog.initialValue || '',
      }}
      validationSchema={{
        [companyEditDialog.field]: companyEditDialog.type === 'text' ? Yup.string().required(fieldValidationRequiredMessage) : Yup.number().typeError("Must be a number."),
      }}
      onSubmit={async (values, actions) => {
        await companyPatch(companyEditDialog.companyId, values);
        actions.setSubmitting(false);
        handleCancel();
      }}
      onCancel={() => handleCancel()}
    >
      <div style={{ width: '500px' }}>
        <Form>
          {
            companyEditDialog.field !== '' ? (
              <Field
                component={FormTextField}
                name={companyEditDialog.field}
                type='text'
                label={companyEditDialog.label}
                isrequired
              />
            ) : null
          }
        </Form>
      </div>
    </DialogForm>
  );
};

export default SettingsCompanyEditDialog;
